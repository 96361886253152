// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1st8yxe-MuiPickersLayout-root {
    display: block !important;
}

.css-1rtg91x-MuiDateCalendar-root {
    margin: unset !important;
    width: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Addevent.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,wBAAwB;IACxB,sBAAsB;AAC1B","sourcesContent":[".css-1st8yxe-MuiPickersLayout-root {\n    display: block !important;\n}\n\n.css-1rtg91x-MuiDateCalendar-root {\n    margin: unset !important;\n    width: auto !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import './footer.css';
import { useTranslation } from 'react-i18next';


export default function Footer() {
    const {t} = useTranslation();
    return (
        <div className='footer'>
            <div>
                {/* <img src='/img/logo.png' alt='companylogo'></img> */}
                <h5>AutoAuction24 AG</h5>
                <p>Sinserstrasse 67</p>
                <p>6330 Cham</p>
                <p>info@autoauktion24.ch</p>
            </div>
            <div className='aboutsectiontwo'>
                <a href="/how-it-works"><p>{t('howitworksmenugeneralinstructions')}</p></a>
                <a href="/how-it-works"><p>{t('howitworksmenupricespervehicle')}</p></a>
                <a href="/how-it-works"><p>{t('howitworksmenugentermscondition')}</p></a>
            </div>
            <div className='aboutsection'>
                <a href="/"><p>{t('auctionMenu')}</p></a>
                <a href="/how-it-works"><p>{t('howItWorksMenu')}</p></a>
                <a href="/register"><p>{t('registrationmenu')}</p></a>
            </div>
        </div>
    )
}


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainheaderh3 {
    margin-top: 1%;
    padding-left: 5%;
}

@media (max-width: 480px) {
    .mainheaderh3 {
        margin-top: 10vh;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Mycar.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".mainheaderh3 {\n    margin-top: 1%;\n    padding-left: 5%;\n}\n\n@media (max-width: 480px) {\n    .mainheaderh3 {\n        margin-top: 10vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import { json, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { fetchData } from '../../services/fetchData';
import CountDown from './countdown';
import './BasicTable.css';
import config from '../../config';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { useTranslation } from 'react-i18next';
import Spinner from '../spinner';
import { Button } from '@mui/material';

export default function BasicTable() {
  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [filterText, setFilterText] = useState('');
  const [filterMileage, setFilterMileage] = useState('');
  const [filterYear, setfilterYear] = useState('');
  const [filterPrice, setfilterPrice] = useState('');
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [favouriteCarIds, setFavouriteCarIds] = useState([]);

  useEffect(() => {
    setIsLoggedIn(!!token);
  
    const getData = async () => {
      setLoading(true);
      const data = await fetchData();
      
      if (data && data.length === undefined) {
        setStartDate(data.startdate);
        setEndDate(data.enddate);
        setRows(data.cars || []);
      } else if (data && data.length > 0) {
        setStartDate(data[0].startdate);
        setEndDate(data[0].enddate);
        setRows(data[0].cars || []);
      }
      
      // Call showFavourites only if the user is logged in
      if (isLoggedIn) {
        await showFavourites();
      }
      setLoading(false);
    };
  
    getData();
  
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isLoggedIn]);
  

  const handleRowClick = (id) => {
    navigate(`/car/${id}`);
  };

  const filteredRows = rows.filter(row => {
    const carName = row.specifications?.car_name || '';
    const mileage = row.specifications?.Mileage || '';
    const carminimumprice = row.minimumprice || '';
    const regDate = row.specifications?.['1st reg'] || '';
    
    // Extract the year from "MM/YYYY"
    const regYear = regDate.split('/')[1];
    
    const matchesName = carName.toLowerCase().includes(filterText.toLowerCase());
    const matchesMileage = filterMileage === '' || Number(mileage) <= filterMileage;
    const matchesPrice = filterPrice === '' || Number(carminimumprice) <= filterPrice;
    const matchesYear = filterYear === '' || regYear === filterYear;
    
    return matchesName && matchesMileage && matchesYear && matchesPrice;
  });

  const handleFavourite = (event, rowId) => {
    event.stopPropagation();
    const bodypayload = {
      "usertoken": token,
      "carid": rowId
    }
    fetch(`${config.API_URL}/markfavouritescar`, {
      "body": JSON.stringify(bodypayload),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      method: 'POST'
    }).then(() => {
      window.location.reload();
    })
  };

  const handleUnfavourite = (event, rowId) => {
    event.stopPropagation();
    const bodypayload = {
      "usertoken": token,
      "carid": rowId
    }
    fetch(`${config.API_URL}/markunfavouritescar`, {
      "body": JSON.stringify(bodypayload),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      method: 'POST'
    }).then(() => {
      window.location.reload();
    })
  }

  const sortedRows = [...filteredRows].sort((a, b) => {
    const isAFavorite = favouriteCarIds.includes(a.id);
    const isBFavorite = favouriteCarIds.includes(b.id);
  
    // Sort by favorite status: favorites on top, others below
    if (isAFavorite && !isBFavorite) return -1;
    if (!isAFavorite && isBFavorite) return 1;
    return 0; // If both are favorites or both are not favorites, keep original order
  });

  async function showFavourites() {
    let fetchfavourites = await fetch(`${config.API_URL}/fetchfavouritecars`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      method: 'GET'
    });
    
    let data = await fetchfavourites.json();
    
    // Extract car IDs from the response and store them in favouriteCarIds state
    const carIds = data.favourites_cars.map(fav => fav.car_id);
    setFavouriteCarIds(carIds);
  }

  

  const renderTable = () => (
    <>
      <Spinner loading={loading}/>
      <TableContainer component={Paper} className="table-container">
        <Table className="mui-table" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className='tableth' style={{ backgroundColor: 'yellow', color: 'black' }}>
              <TableCell align="right"></TableCell>
              <TableCell>{t('imageInTable')}</TableCell>
              <TableCell>{t('vehicleInTable')}</TableCell>
              <TableCell align="right">{t('firstVehicleRegistration')}</TableCell>
              <TableCell align="right">{t('kmInTable')}</TableCell>
              <TableCell align="right">{t('minimumPriceInTable')}</TableCell>
              {isLoggedIn ? (
                <TableCell align="right">Current Bid Price</TableCell>
              ) : <TableCell />
              }
            </TableRow>
          </TableHead>
          <TableBody>
          {sortedRows.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5} align="center">{t('noCarsAvailable')}</TableCell>
            </TableRow>          
          ) : (
            sortedRows.map((row) => {
                const hasValidData = row.specifications && row.specifications.car_name;
                const carName = row.specifications?.car_name || 'Unknown Car Name';
                const firstImage = row.first_image ? `${config.API_URL}${row.first_image}` : '';
                const regDate = row.specifications?.['1st reg'] || 'N/A';
                const mileage = row.specifications?.['Mileage'] || 'N/A';
                const minPrice = isLoggedIn ? (row.minimumprice !== null ? row.minimumprice : 'N/A') : t('loginToSeePrice');
                const isFavorite = favouriteCarIds.includes(row.id);
                const currentBidPrice = isLoggedIn ? (row.currentbidprice == 0 ? row.minimumprice : row.currentbidprice  ) : t('loginToSeePrice');
                const carTitle = `${row.specifications?.car_name}  ${row.specifications.carmodel}, ${row.specifications.car_type}PS`;
                const carDescription = `${row.specifications?.Gear}, ${row.specifications.Body}`;

                return (
                  <TableRow 
                    key={row.id} 
                    onClick={hasValidData ? () => handleRowClick(row.id) : undefined} 
                    style={{ cursor: hasValidData ? 'pointer' : 'default'}}
                  >
                    {isLoggedIn ? (
                      isFavorite ? (
                        <TableCell align="left" sx={{ width: '50px', paddingLeft: '10px' }}>
                          <Button onClick={(event) => handleUnfavourite(event, row.id)}>
                            <img src='/img/favouritestar.png' alt="Star" style={{ maxWidth: '20px' }} />
                          </Button>
                        </TableCell>
                      ) : (
                        <TableCell align="left" sx={{ width: '50px', paddingLeft: '10px' }}>
                          <Button onClick={(event) => handleFavourite(event, row.id)}>
                            <img src='/img/unfavouritestar.png' alt="Star" style={{ maxWidth: '20px' }} />
                          </Button>
                        </TableCell>
                      )
                    ) : (
                      <TableCell>
                      </TableCell>
                    )}
                    
                    <TableCell className='imagetablecell'>
                      <div className="image-container">
                        {firstImage ? (
                          <>
                            <img src={firstImage} alt={carName} className="thumbnail" />
                            <div className="overlay">
                              <img src={firstImage} alt={carName} className="enlarged" />
                            </div>
                          </>
                        ) : (
                          <span>{t('noImageAvailable')}</span>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>{carTitle}</span>
                        <br />
                        <span style={{ fontSize: 'small', color: 'gray' }}>{carDescription}</span>
                      </div>
                    </TableCell>
                    <TableCell align="right">{regDate}</TableCell>
                    <TableCell align="right">{mileage}</TableCell>
                    <TableCell align="right">{minPrice}</TableCell>
                    {isLoggedIn ? (
                      <TableCell align="right">{currentBidPrice}</TableCell>
                    ) : <TableCell />}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const renderCard = (row) => {
    const isFavorite = favouriteCarIds.includes(row.id);
    const currentBidPrice = isLoggedIn ? (row.currentbidprice == 0 ? row.minimumprice : row.currentbidprice  ) : t('loginToSeePrice');
    const carTitle = `${row.specifications?.car_name}  ${row.specifications.carmodel}, ${row.specifications.car_type}PS`
    const carDescription = `${row.specifications?.Gear}, ${row.specifications.Body}`;
    
    return (
      <div
        key={row.id}
        className="card"
        onClick={() => handleRowClick(row.id)}
      >
        <div className="card-header">
          <img
            src={row.first_image ? `${config.API_URL}${row.first_image}` : ''}
            alt={row.specifications?.car_name || 'Unknown Car'}
          />
        </div>
        <div className="card-body">
          <h3 style={{fontWeight: "bold"}}>{carTitle || 'Unknown Car Name'}</h3>
          <p>{carDescription || 'Car Description'}</p>
          <p><strong>{t('firstVehicleRegistration')}:</strong> {row.specifications?.['1st reg'] || 'N/A'}</p>
          <p><strong>{t('kmInTable')}:</strong> {row.specifications?.['Mileage'] || 'N/A'}</p>
          <p><strong>{t('minimumPriceInTable')}:</strong> {isLoggedIn ? (row.minimumprice !== null ? row.minimumprice : 'N/A') : t('loginToSeePrice')}</p>
          {isLoggedIn ? (
            isFavorite ? (
              <Button onClick={(event) => handleUnfavourite(event, row.id)}><img src='/img/favouritestar.png' alt="Star" style={{ maxWidth: '20px' }} /></Button>
            ) : (
              <>
            <p><strong>Current bid price :</strong>{currentBidPrice}</p>       
             <Button onClick={(event) => handleFavourite(event, row.id)}><img src='/img/unfavouritestar.png' alt="Star" style={{ maxWidth: '20px' }} /></Button>
              </>
            )
          ) : null}
        </div>
      </div>
    );
  };
  

  return (
    <>
      <CountDown targetDate={startDate} endTargetDate={endDate} />
      {isLoggedIn && (

      <div className='filters'>
        <h3>Filter</h3>
        <div className='filtersdiv'>
          <TextField
            label={t("carNameFilter")}
            variant="outlined"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <TextField
            label={t("mileageGreaterThan")}
            variant="outlined"
            value={filterMileage}
            onChange={(e) => setFilterMileage(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>{t('firstVehicleRegistration')}</InputLabel>
            <Select
              value={filterYear}
              onChange={(e) => setfilterYear(e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2022">2022</MenuItem>
              <MenuItem value="2021">2021</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={t("priceMaximum")}
            variant="outlined"
            value={filterPrice}
            onChange={(e) => setfilterPrice(e.target.value)}
          />
        </div>
      </div>
      )}
      {isMobile ? (
        <div className="cards-container">
          {sortedRows.map((row) => renderCard(row))}
        </div>
      ) : (
        renderTable()
      )}
    </>
  );
}

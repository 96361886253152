// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1dxi1m5 {
    flex-direction: row !important;
    row-gap: 1rem;
    column-gap: 10% !important;
}

.css-cpgvjg-MuiSnackbar-root {
    left: auto !important;
    right: auto !important;
    top: 20% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/car/Counter.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,aAAa;IACb,0BAA0B;AAC9B;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".css-1dxi1m5 {\n    flex-direction: row !important;\n    row-gap: 1rem;\n    column-gap: 10% !important;\n}\n\n.css-cpgvjg-MuiSnackbar-root {\n    left: auto !important;\n    right: auto !important;\n    top: 20% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

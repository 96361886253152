import React from "react";

export default function GeneralCondition() {
    return (
        <>
            <div style={{fontFamily: 'Poppins'}}>
                <h2>1. General information</h2>
                <p>
                    AutoAuction24 AG (CHE-292.848.039; in the following: "AutoAuction24") operates an internet auction platform in the business-to-business sector and offers various modularly structured services in vehicle remarketing.
                    The legal relationship between AutoAuction24 and its users in connection with the use of AutoAuction24 services is governed by the following GTC, as well as the annexes listed separately therein and any other individual agreements concluded in writing.
                    By registering on the auction platform, the user accepts these GTC without limitation. As an integral part of these GTC in connection with the use of AutoAuction24 services, the parties hereby expressly agree to the currently valid AutoAuction24 terms of purchase and the current AutoAuction24 price list, which can be viewed at any time via link.
                </p>
                
                <h2>2. Auction</h2>
                <p>
                    The auction describes the internet auction conducted on the AutoAuction24 "platform". The individual procedures up to the conclusion of the auction can be found in the respective valid terms of purchase.
                    <br /><br />
                    In contrast to the conventional auction pursuant to Art. 229 Para. 2 and 3 CO, the auction is not closed by award in the absence of other bids, but either:
                </p>
                <ul>
                    <li>When an end date is reached, whereby a legally valid purchase contract between the publisher and the bidder always depends on the acceptance of the respective publisher, if the minimum price has not been reached, or</li>
                    <li>If the advertiser demands a minimum offer (option fixed price) - immediately if a bidder offers a bid with at least the amount of the required fixed price.</li>
                </ul>
                <p>
                    With the conclusion of the auction, a purchase contract is automatically concluded between the seller and the bidder within the meaning of Art. 187 et seq. CO.
                </p>

                <h2>3. Users of the AutoAuction24 internet auction platform</h2>
                <p>
                    Free registration as a user is required in order to be allowed to use the AutoAuction24 platform. Participation as a user is only possible after acceptance of the application and activation of the account by AutoAuction24.
                    <br /><br />
                    AutoAuction24 reserves the express right to reject registration applications or to exclude users without justification. 
                </p>

                {/* Repeat similar structure for the remaining sections */}
                {/* Add more <h2> and <p> tags for each section as needed */}
            </div>
        </>
    );
}

import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './Cartoggleinfo.css';

export default function Cartoggleinfo({ accordionData }) {
  return (
    <div className='cartoggle'>
      {accordionData.map((item, index) => (
        <Accordion key={index} defaultExpanded={index !== 1}>
          <AccordionSummary className='accordingbody'
            expandIcon={index === 0 ? <ArrowDownwardIcon /> : <ArrowDropDownIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {item.details.columns ? (
              <Grid container spacing={2}>
                {item.details.columns.map((column, colIndex) => (
                  <Grid item xs={4} key={colIndex}>
                    {column.map((detail, detailIndex) => (
                      <Typography key={detailIndex}>{detail}</Typography>
                    ))}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>{item.details}</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

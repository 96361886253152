import React, { useState, useEffect } from 'react';
import { Box, Divider, TextField, Button, Container, Typography, MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { registerCar, getFutureEvents } from '../services/fetchData';
import Header from '../components/header/Header';
import config from '../config';
import { loadStripe } from '@stripe/stripe-js';
import InputAdornment from '@mui/material/InputAdornment';
import carBrands from './carBrands.json';
import { Trans, useTranslation } from 'react-i18next';
import Spinner from '../components/spinner';
import './Registercar.css'
import Grid from '@mui/material/Grid';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51Kpg5WFdJRiLUWhUcRuEuemQEMZgh7Zoxau8siwAMW69LvbfCvv7XohGuY23Y4msl61oaDVUhytWq8zBbAVLD9ZK00GpoWgirz');

const ItemType = 'IMAGE';

const DraggableImage = ({ preview, index, moveImage, handleRemoveImage }) => {
  const [{ isDragging }, ref] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => ref(drop(node))}
      style={{
        position: 'relative',
        display: 'inline-block',
        margin: '5px',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <img
        src={preview}
        alt={`Preview ${index}`}
        style={{
          width: '100px',
          height: '100px',
          objectFit: 'cover',
          border: '1px solid #ddd',
          borderRadius: '5px',
        }}
      />
      <IconButton
        onClick={() => handleRemoveImage(index)}
        style={{
          position: 'absolute',
          top: '-10px',
          right: '-10px',
          background: 'white',
        }}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </div>
  );
};

export default function RegisterCar() {
  const navigate = useNavigate();
  const [carName, setCarName] = useState('');
  const [carAccident, setCarAccident] = useState('');
  const [cartype, setcartype] = useState('');
  const [minimumPrice, setMinimumPrice] = useState('');
  const [body, setBody] = useState('');
  const [fuel, setFuel] = useState('');
  const [gear, setGear] = useState('');
  const [firstReg, setFirstReg] = useState('');
  const [mileage, setMileage] = useState('');
  const [interior, setInterior] = useState('');
  const [wheelDrive, setWheelDrive] = useState('');
  const [displacement, setDisplacement] = useState('');
  const [exteriorColour, setExteriorColour] = useState('');
  const [numberOfSeats, setNumberOfSeats] = useState('');
  const [transport, setTransport] = useState('');
  const [eventid, seteventid] = useState('');
  const [events, setEvents] = useState([]);
  const [imageInputs, setImageInputs] = useState([{ id: Date.now(), files: [], previews: [] }]);
  const [specialEquipment, setSpecialEquipment] = useState('');
  const [serialEquipment, setSerialEquipment] = useState('');
  const [vehicleInspection, setVehicleInspection] = useState('');
  const [numberOfKeys, setNumberOfKeys] = useState('');
  const [vehicleRegistrationDocument, setvehicleRegistrationDocument] = useState('');
  const [serviceRecordBooklet, setserviceRecordBooklet] = useState('');
  const [mechanics, setmechanics] = useState('');
  const [carfinish, setcarfinish] = useState('');
  const [carmodel, setCarmodel] = useState('');
  const [vehicleidentificationnumber, setvehicleidentificationnumber] = useState('');
  const [carmasternumber, setcarmasternumber] = useState('');
  const [serviceelectrical, setserviceelectrical] = useState('');
  const [generalcondition, setgeneralcondition] = useState('');
  const [repairamount, setrepairamount] = useState('');
  const [expertReport, setexpertReport] = useState('');
  const [registercartype, setregistercartype] = useState('');
  const [interiortype, setinteriortype] = useState('');
  const [carregistertypeapproval, setcarregistertypeapproval] = useState('');
  const [bodydescription, setbodydescription] = useState('');
  const [paintcondition, setpaintcondition] = useState('');
  const [otherdescription, setotherdescription] = useState('');
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedSection, setSelectedSection] = useState('carData');
  const [carperformance, setCarperformance] = useState('');
  // const [publishcode, setpublishcode] = useState('');
  const convertKwToPs = (kw) => {
    return Math.round(kw * 1.36);
  };
  const [sectionValidation, setSectionValidation] = useState({
    carData: true,
    condition: true,
    documents: true,
    publish: true,
  });

  const validateSections = () => {
    const imagesValid = imageInputs.some((input) => input.files && input.files.length > 0);
    const sectionValidation = {
      carData: !!(
        carName &&
        carmodel &&
        firstReg &&
        mileage &&
        body &&
        wheelDrive &&
        gear &&
        fuel &&
        displacement &&
        numberOfSeats &&
        exteriorColour &&
        interior
      ),
      condition: !!(
        carAccident &&
        serviceRecordBooklet &&
        numberOfKeys
      ),
      documents: !!imagesValid,
      publish: !!eventid,
    };
    const missingFields = {
      carData: [],
      condition: [],
      documents: [],
      publish: [],
    };

    // Validate Car Data
    if (!carName) missingFields.carData.push('Car Name');
    if (!carmodel) missingFields.carData.push('Car Model');
    if (!firstReg) missingFields.carData.push('1st Registration');
    if (!mileage) missingFields.carData.push('Mileage');
    if (!body) missingFields.carData.push('Body');
    if (!wheelDrive) missingFields.carData.push('Wheel Drive');
    if (!gear) missingFields.carData.push('Gear');
    if (!fuel) missingFields.carData.push('Fuel');
    if (!displacement) missingFields.carData.push('Displacement');
    if (!numberOfSeats) missingFields.carData.push('Number of Seats');
    if (!exteriorColour) missingFields.carData.push('Exterior Colour');
    if (!interior) missingFields.carData.push('Interior');

    // Validate Condition
    if (!carAccident) missingFields.condition.push('Car Accident');
    if (!serviceRecordBooklet) missingFields.condition.push('Service Record Booklet');
    if (!numberOfKeys) missingFields.condition.push('Number of Keys');

    // Validate Documents
    if (!imagesValid) missingFields.documents.push('Images');

    // Validate Publish
    if (!eventid) missingFields.publish.push('Event ID');

    // Check overall validation status
    const isValid = Object.values(missingFields).every((fields) => fields.length === 0);

    return { isValid, missingFields, sectionValidation };
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const data = await getFutureEvents();
      setEvents(data.slice(0, 3));
    };

    if (carperformance) {
      setcartype(convertKwToPs(carperformance));
    } else {
      setcartype('');
    }

    fetchEvents();
  }, [carperformance]);

  const moveImage = (fromIndex, toIndex) => {
    setImageInputs((prevInputs) => {
      const newInputs = [...prevInputs];
      if (!newInputs[0] || !newInputs[0].previews || !newInputs[0].files) return prevInputs;
      const previews = [...newInputs[0].previews];
      const files = [...newInputs[0].files];
      const [movedPreview] = previews.splice(fromIndex, 1);
      const [movedFile] = files.splice(fromIndex, 1);
      previews.splice(toIndex, 0, movedPreview);
      files.splice(toIndex, 0, movedFile);
      return [{ ...newInputs[0], previews, files }];
    });
  };

  const handleFileChange = (index, event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) => file.size <= 5 * 1024 * 1024);

    if (validFiles.length > 0) {
      setImageInputs([{ id: Date.now(), files: validFiles, previews: validFiles.map((file) => URL.createObjectURL(file)) }]);
    }
  };

  const handlePdfChange = (e) => {
    setexpertReport(e.target.files[0]);
  };

  const handleRemoveImage = (inputIndex, imageIndex) => {
    const newImageInputs = [...imageInputs];
    const updatedFiles = [...newImageInputs[inputIndex].files];
    const updatedPreviews = [...newImageInputs[inputIndex].previews];

    updatedFiles.splice(imageIndex, 1);
    updatedPreviews.splice(imageIndex, 1);

    newImageInputs[inputIndex] = {
      ...newImageInputs[inputIndex],
      files: updatedFiles,
      previews: updatedPreviews,
    };

    setImageInputs(newImageInputs);
  };

  const handleRemoveImageInput = (index) => {
    const newImageInputs = imageInputs.filter((input, idx) => idx !== index);
    setImageInputs(newImageInputs);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // if (publishcode != 'FREETRIAL') {
    //   alert(`Wrong Code`)
    //   setLoading(false);
    //   return;
    // }

    const { isValid, missingFields, sectionValidation } = validateSections();
    setSectionValidation(sectionValidation);
    if (!isValid) {
      const alertMessage = Object.entries(missingFields)
        .filter(([_, fields]) => fields.length > 0)
        .map(
          ([section, fields]) =>
            `In ${section}: ${fields.join(', ')}`
        )
        .join('\n');

      alert(`Please fill out all required fields:\n${alertMessage}`);
      setLoading(false);
      return;
    }

    const [year, month] = firstReg.split('-');
    const formattedDate = `${month}/${year}`;

    try {
      const carSpecifications = {
        car_name: carName,
        carmodel: carmodel,
        car_accident: carAccident,
        car_type: cartype,
        Body: body,
        [t('carregisterfuel')]: fuel,
        Gear: gear,
        "1st reg": formattedDate,
        Mileage: mileage,
        Interior: interior,
        "Wheel drive": wheelDrive,
        Displacement: displacement,
        "Exterior_colour": exteriorColour,
        "Number of seats": numberOfSeats,
        [t('carregistertobetransported')]: transport,
        "vehicleInspection": vehicleInspection,
        "numberOfKeys": numberOfKeys,
        [t('carregistervehicleregistrationdocument')]: vehicleRegistrationDocument,
        "serviceRecordBooklet": serviceRecordBooklet,
        "mechanics": mechanics,
        "carfinish": carfinish,
        "vehicleidentificationnumber": vehicleidentificationnumber,
        "carmasternumber": carmasternumber,
        "serviceelectrical": serviceelectrical,
        "generalcondition": generalcondition,
        "repairamount": repairamount,
        "registercartype": registercartype,
        "interiortype": interiortype,
        [t('carperformance')]: carperformance,
        "bodydescription": bodydescription,
        "paintcondition": paintcondition,
        "otherdescription": otherdescription,
        "carregistertypeapproval": carregistertypeapproval
      };

      const formData = new FormData();
      formData.append('car_specifications', JSON.stringify(carSpecifications));
      formData.append('special_equipment', specialEquipment)
      formData.append('serial_equipment', serialEquipment)
      formData.append('minimum_price', minimumPrice);
      formData.append('expertReport', expertReport);
      formData.append('eventid', eventid);
      imageInputs[0].files.forEach((file) => {
        formData.append('images', file);
      });


      const response = await registerCar(formData);
      const carId = response.car_id;
      // const payment = await fetch(`${config.API_URL}/create-checkout-session`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ car_id: carId }),  // Send car_id in the request body
      // });

      // const sessionId = await payment.json();

      // // Initialize Stripe with the publishable key
      // const stripe = await stripePromise;

      // Redirect to Checkout
      // const { error } = await stripe.redirectToCheckout({
      //   sessionId: sessionId.sessionId,
      // });
      navigate('/')
      // setLoading(false);
    } catch (error) {
      console.error('Failed to register car:', error);
    }
  };

  const getButtonStyle = (section) => ({
    marginTop: '10px',
    color: sectionValidation[section] ? 'inherit' : 'red',
    borderColor: sectionValidation[section] ? 'inherit' : 'red',
  });

  return [
    <DndProvider backend={HTML5Backend}>
      <Header />
      <Spinner loading={loading} />
      <div className='registercardiv'>
        <Box
          sx={{
            width: '250px',
            padding: '20px',
            boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
          }}
          className="sidebarbox"
        >
          <Typography variant="h5" gutterBottom>
            {t('newCar')}
          </Typography>
          <Divider />
          <Button
            fullWidth
            variant={selectedSection === 'carData' ? 'contained' : 'outlined'}
            onClick={() => setSelectedSection('carData')}
            sx={{
              ...getButtonStyle('carData'),
              bgcolor: selectedSection === 'carData' ? 'rgb(239, 239, 227)' : 'inherit',
              color: selectedSection === 'carData' ? 'black' : 'inherit',
              '&:hover': {
                bgcolor: selectedSection === 'carData' ? 'rgb(197 197 187)' : 'inherit',
                color: selectedSection === 'carData' ? 'black' : 'inherit',
              },
            }}
          >
            {t('cardata')}
          </Button>
          <Button
            fullWidth
            variant={selectedSection === 'condition' ? 'contained' : 'outlined'}
            onClick={() => setSelectedSection('condition')}
            sx={{
              ...getButtonStyle('condition'),
              bgcolor: selectedSection === 'condition' ? 'rgb(239, 239, 227)' : 'inherit',
              color: selectedSection === 'condition' ? 'black' : 'inherit',
              '&:hover': {
                bgcolor: selectedSection === 'condition' ? 'rgb(197 197 187)' : 'inherit',
                color: selectedSection === 'condition' ? 'black' : 'inherit',
              },
            }}
          >
            {t('condition')}
          </Button>
          <Button
            fullWidth
            variant={selectedSection === 'documents' ? 'contained' : 'outlined'}
            onClick={() => setSelectedSection('documents')}
            sx={{
              ...getButtonStyle('documents'),
              bgcolor: selectedSection === 'documents' ? 'rgb(239, 239, 227)' : 'inherit',
              color: selectedSection === 'documents' ? 'black' : 'inherit',
              '&:hover': {
                bgcolor: selectedSection === 'documents' ? 'rgb(197 197 187)' : 'inherit',
                color: selectedSection === 'documents' ? 'black' : 'inherit',
              },
            }}
          >
            {t('documentsandphotos')}
          </Button>
          <Button
            fullWidth
            variant={selectedSection === 'publish' ? 'contained' : 'outlined'}
            onClick={() => setSelectedSection('publish')}
            sx={{
              ...getButtonStyle('publish'),
              bgcolor: selectedSection === 'publish' ? 'rgb(239, 239, 227)' : 'inherit',
              color: selectedSection === 'publish' ? 'black' : 'inherit',
              '&:hover': {
                bgcolor: selectedSection === 'publish' ? 'rgb(197 197 187)' : 'inherit',
                color: selectedSection === 'publish' ? 'black' : 'inherit',
              },
            }}
          >
            {t('publish')}
          </Button>
        </Box>
        <Container>
          <form onSubmit={handleSubmit}>
            {selectedSection === 'carData' && (
              <>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregistercarbrand"></Trans></InputLabel>
                      <Select
                        value={carName}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                        onChange={(e) => setCarName(e.target.value)}
                        label="Car Brand">
                        {carBrands.map((brand, index) => (
                          <MenuItem key={index} value={brand}>
                            {brand}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregistermodel')}
                      fullWidth
                      margin="normal"
                      required
                      value={carmodel}
                      onChange={(e) => setCarmodel(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('registercartype')}
                      fullWidth
                      margin="normal"
                      value={registercartype}
                      onChange={(e) => setregistercartype(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregisterfirstreg')}
                      fullWidth
                      margin="normal"
                      required
                      value={firstReg}
                      onChange={(e) => setFirstReg(e.target.value)}
                      type="month"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregistermileage')}
                      fullWidth
                      margin="normal"
                      required
                      value={mileage}
                      onChange={(e) => setMileage(e.target.value)}
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregisterbody"></Trans></InputLabel>
                      <Select
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        label="Body"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('carregisterbodylimousine')}><Trans i18nKey="carregisterbodylimousine"></Trans></MenuItem>
                        <MenuItem value={t('carregisterbodyestatecar')}><Trans i18nKey="carregisterbodyestatecar"></Trans></MenuItem>
                        <MenuItem value={t('carregisterbodyconvertible')}><Trans i18nKey="carregisterbodyconvertible"></Trans></MenuItem>
                        <MenuItem value={t('carregisterbodycoupe')}><Trans i18nKey="carregisterbodycoupe"></Trans></MenuItem>
                        <MenuItem value={t('carregisterbodyhatchback')}><Trans i18nKey="carregisterbodyhatchback"></Trans></MenuItem>
                        <MenuItem value={t('carregisterbodysuvoffroader')}><Trans i18nKey="carregisterbodysuvoffroader"></Trans></MenuItem>
                        <MenuItem value={t('carregisterbodyvan')}><Trans i18nKey="carregisterbodyvan"></Trans></MenuItem>
                        <MenuItem value={t('carregisterbodyroadster')}><Trans i18nKey="carregisterbodyroadster"></Trans></MenuItem>
                        <MenuItem value={t('carregisterbodypickup')}><Trans i18nKey="carregisterbodypickup"></Trans></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={3}>
                    <TextField
                      label={t('carregistertype')}
                      fullWidth
                      margin="normal"
                      value={cartype}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      label={t('carperformance')}
                      required
                      fullWidth
                      margin="normal"
                      value={carperformance}
                      onChange={(e) => setCarperformance(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregisterwheeldrive"></Trans></InputLabel>
                      <Select
                        value={wheelDrive}
                        onChange={(e) => setWheelDrive(e.target.value)}
                        label="Wheel drive"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('carregisterwheeldrivefront')}><Trans i18nKey="carregisterwheeldrivefront"></Trans></MenuItem>
                        <MenuItem value={t('carregisterwheeldriverear')}><Trans i18nKey="carregisterwheeldriverear"></Trans></MenuItem>
                        <MenuItem value={t('carregisterwheeldrive4x4')}><Trans i18nKey="carregisterwheeldrive4x4"></Trans></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>


                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregistergear"></Trans></InputLabel>
                      <Select
                        value={gear}
                        onChange={(e) => setGear(e.target.value)}
                        label="Gear"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('carregistergearmanual')}><Trans i18nKey="carregistergearmanual"></Trans></MenuItem>
                        <MenuItem value={t('carregistergearautomatic')}><Trans i18nKey="carregistergearautomatic"></Trans></MenuItem>
                        <MenuItem value={t('carregistergearsemiautomatic')}><Trans i18nKey="carregistergearsemiautomatic"></Trans></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregisterfuel"></Trans></InputLabel>
                      <Select
                        value={fuel}
                        onChange={(e) => setFuel(e.target.value)}
                        label="Fuel"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('carregisterfuelpetrol')}><Trans i18nKey="carregisterfuelpetrol"></Trans></MenuItem>
                        <MenuItem value={t('carregisterfueldiesel')}><Trans i18nKey="carregisterfueldiesel"></Trans></MenuItem>
                        <MenuItem value={t('carregisterfuelelectrical')}><Trans i18nKey="carregisterfuelelectrical"></Trans></MenuItem>
                        <MenuItem value={t('carregisterfuelgascng')}><Trans i18nKey="carregisterfuelgascng"></Trans></MenuItem>
                        <MenuItem value={t('carregisterfuelhybrid')}><Trans i18nKey="carregisterfuelhybrid"></Trans></MenuItem>
                        <MenuItem value={t('carregisterfuelgaslpg')}><Trans i18nKey="carregisterfuelgaslpg"></Trans></MenuItem>
                        <MenuItem value={t('carregisterfuelhybridpetrol')}><Trans i18nKey="carregisterfuelhybridpetrol"></Trans></MenuItem>
                        <MenuItem value={t('carregisterfuelhybriddiesel')}><Trans i18nKey="carregisterfuelhybriddiesel"></Trans></MenuItem>
                        <MenuItem value={t('carregisterfuelhydrogen')}><Trans i18nKey="carregisterfuelhydrogen"></Trans></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregisterdisplacement')}
                      fullWidth
                      margin="normal"
                      required
                      value={displacement}
                      onChange={(e) => setDisplacement(e.target.value)}
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">liters</InputAdornment>,
                        inputProps: { min: 0 }, // Optional: Prevent negative numbers
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregisternumberofseats"></Trans></InputLabel>
                      <Select
                        value={numberOfSeats}
                        onChange={(e) => setNumberOfSeats(e.target.value)}
                        label="Number of seats"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                </Grid>


                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregisterexteriorcolour"></Trans></InputLabel>
                      <Select
                        value={exteriorColour}
                        onChange={(e) => setExteriorColour(e.target.value)}
                        label="Exterior colour"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('carregisterexteriorcolourblack')}><Trans i18nKey="carregisterexteriorcolourblack"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourwhite')}><Trans i18nKey="carregisterexteriorcolourwhite"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourred')} ><Trans i18nKey="carregisterexteriorcolourred"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourblue')} ><Trans i18nKey="carregisterexteriorcolourblue"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourgreen')} ><Trans i18nKey="carregisterexteriorcolourgreen"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcoloursilver')} ><Trans i18nKey="carregisterexteriorcoloursilver"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourgray')}><Trans i18nKey="carregisterexteriorcolourgray"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolouryellow')} ><Trans i18nKey="carregisterexteriorcolouryellow"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourorange')} ><Trans i18nKey="carregisterexteriorcolourorange"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourbrown')} ><Trans i18nKey="carregisterexteriorcolourbrown"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolouranthracite')} ><Trans i18nKey="carregisterexteriorcolouranthracite"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourbeige')} ><Trans i18nKey="carregisterexteriorcolourbeige"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourbordeaux')} ><Trans i18nKey="carregisterexteriorcolourbordeaux"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourgold')} ><Trans i18nKey="carregisterexteriorcolourgold"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourturquoise')} ><Trans i18nKey="carregisterexteriorcolourturquoise"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourviolet')} ><Trans i18nKey="carregisterexteriorcolourviolet"></Trans></MenuItem>
                        <MenuItem value={t('carregisterexteriorcolourpink')} ><Trans i18nKey="carregisterexteriorcolourpink"></Trans></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel><Trans i18nKey="carregistercarfinish"></Trans></InputLabel>
                      <Select
                        value={carfinish}
                        onChange={(e) => setcarfinish(e.target.value)}
                        label="Car finish"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('carregistercarfinisbubbleeffect')}><Trans i18nKey="carregistercarfinisbubbleeffect"></Trans></MenuItem>
                        <MenuItem value={t('carregistercarfinishindividualpaint')}><Trans i18nKey="carregistercarfinishindividualpaint"></Trans></MenuItem>
                        <MenuItem value={t('carregistercarfinishmattpaint')} ><Trans i18nKey="carregistercarfinishmattpaint"></Trans></MenuItem>
                        <MenuItem value={t('carregistercarfinishmetallic')} ><Trans i18nKey="carregistercarfinishmetallic"></Trans></MenuItem>
                        <MenuItem value={t('carregistercarfinishsolidpaint')} ><Trans i18nKey="carregistercarfinishsolidpaint"></Trans></MenuItem>
                        <MenuItem value={t('carregisterinteriorother')}><Trans i18nKey="carregisterinteriorother"></Trans></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregisterinterior"></Trans></InputLabel>
                      <Select
                        value={interior}
                        onChange={(e) => setInterior(e.target.value)}
                        label="Interior"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('carregisterinteriorblack')}><Trans i18nKey="carregisterinteriorblack"></Trans></MenuItem>
                        <MenuItem value={t('carregisterinteriorbeige')}><Trans i18nKey="carregisterinteriorbeige"></Trans></MenuItem>
                        <MenuItem value={t('carregisterinteriorgray')}><Trans i18nKey="carregisterinteriorgray"></Trans></MenuItem>
                        <MenuItem value={t('carregisterinteriorbrown')}><Trans i18nKey="carregisterinteriorbrown"></Trans></MenuItem>
                        <MenuItem value={t('carregisterinteriorred')}><Trans i18nKey="carregisterinteriorred"></Trans></MenuItem>
                        <MenuItem value={t('carregisterinteriorblue')}><Trans i18nKey="carregisterinteriorblue"></Trans></MenuItem>
                        <MenuItem value={t('carregisterinteriorwhite')}><Trans i18nKey="carregisterinteriorwhite"></Trans></MenuItem>
                        <MenuItem value={t('carregisterinteriorgreen')}><Trans i18nKey="carregisterinteriorgreen"></Trans></MenuItem>
                        <MenuItem value={t('carregisterinteriortan')}><Trans i18nKey="carregisterinteriortan"></Trans></MenuItem>
                        <MenuItem value={t('carregisterinteriorcream')}><Trans i18nKey="carregisterinteriorcream"></Trans></MenuItem>
                        <MenuItem value={t('carregisterinteriorother')}><Trans i18nKey="carregisterinteriorother"></Trans></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="interiortype"></Trans></InputLabel>
                      <Select
                        value={interiortype}
                        onChange={(e) => setinteriortype(e.target.value)}
                        label="Interior Type"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('interiortypealcantara')}><Trans i18nKey="interiortypealcantara"></Trans></MenuItem>
                        <MenuItem value={t('interiortypeleder')} ><Trans i18nKey="interiortypeleder"></Trans></MenuItem>
                        <MenuItem value={t('interiortypestaff')} ><Trans i18nKey="interiortypestaff"></Trans></MenuItem>
                        <MenuItem value={t('interiortypestaffalcantara')}><Trans i18nKey="interiortypestaffalcantara"></Trans></MenuItem>
                        <MenuItem value={t('interiortypestoffleder')} ><Trans i18nKey="interiortypestoffleder"></Trans></MenuItem>
                        <MenuItem value={t('interiortypevolleder')} ><Trans i18nKey="interiortypevolleder"></Trans></MenuItem>
                        <MenuItem value={t('carregisterinteriorother')}><Trans i18nKey="carregisterinteriorother"></Trans></MenuItem>

                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <button className='continuebuttonregister' onClick={() => setSelectedSection('condition')}>{t('continuebutton')}</button>
              </>

            )}
            {selectedSection === 'condition' && (
              <>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregistercaraccident"></Trans></InputLabel>
                      <Select
                        value={carAccident}
                        onChange={(e) => setCarAccident(e.target.value)}
                        label={t('carregisteraccident')}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('carregistercaraccidentyes')}><Trans i18nKey="carregistercaraccidentyes"></Trans></MenuItem>
                        <MenuItem value={t('carregistercaraccidentno')}><Trans i18nKey="carregistercaraccidentno"></Trans></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregistervehicleinspection')}
                      fullWidth
                      margin="normal"
                      required
                      value={vehicleInspection}
                      onChange={(e) => setVehicleInspection(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregistervehicleregistrationdocument"></Trans></InputLabel>
                      <Select
                        value={vehicleRegistrationDocument}
                        onChange={(e) => setvehicleRegistrationDocument(e.target.value)}
                        label="Vehicle registration document"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('carregistercarregistervehicleavailable')}><Trans i18nKey="carregistercarregistervehicleavailable"></Trans></MenuItem>
                        <MenuItem value={t('carregistercarregistervehiclewillprovidelater')}><Trans i18nKey="carregistercarregistervehiclewillprovidelater"></Trans></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregisterservicerecordbooklet"></Trans></InputLabel>
                      <Select
                        value={serviceRecordBooklet}
                        onChange={(e) => setserviceRecordBooklet(e.target.value)}
                        label="Service record booklet"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('carregisterservicerecordbookletavailable')}><Trans i18nKey="carregisterservicerecordbookletavailable"></Trans></MenuItem>
                        <MenuItem value={t('carregisterservicerecordbookletincomplete')}><Trans i18nKey="carregisterservicerecordbookletincomplete"></Trans></MenuItem>
                        <MenuItem value={t('carregisterservicerecordbookletmissing')}><Trans i18nKey="carregisterservicerecordbookletmissing"></Trans></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel><Trans i18nKey="carregisterserviceelectrical"></Trans></InputLabel>
                      <Select
                        value={serviceelectrical}
                        onChange={(e) => setserviceelectrical(e.target.value)}
                        label="Service Electrical"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('carregisterserviceelectricalavailable')}><Trans i18nKey="carregisterserviceelectricalavailable"></Trans></MenuItem>
                        <MenuItem value={t('carregisterserviceelectricalincomplete')}><Trans i18nKey="carregisterserviceelectricalincomplete"></Trans></MenuItem>
                        <MenuItem value={t('carregisterservicerecordelectricalmissing')}><Trans i18nKey="carregisterservicerecordelectricalmissing"></Trans></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregisternumberofkeys"></Trans></InputLabel>
                      <Select
                        value={numberOfKeys}
                        onChange={(e) => setNumberOfKeys(e.target.value)}
                        label="Number of keys"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel><Trans i18nKey="carregistergeneralcondition"></Trans></InputLabel>
                      <Select
                        value={generalcondition}
                        onChange={(e) => setgeneralcondition(e.target.value)}
                        label="General Condition"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value={t('carregistergeneralconditionhaildamage')} ><Trans i18nKey="carregistergeneralconditionhaildamage"></Trans></MenuItem>
                        <MenuItem value={t('carregistergeneralconditiondogowner')} ><Trans i18nKey="carregistergeneralconditiondogowner"></Trans></MenuItem>
                        <MenuItem value={t('carregistergeneralconditionsmokervehicle')} ><Trans i18nKey="carregistergeneralconditionsmokervehicle"></Trans></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregisterrepairsamount')}
                      fullWidth
                      margin="normal"
                      type="number"
                      value={repairamount}
                      onChange={(e) => setrepairamount(e.target.value)}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregistervehicleidentificationnumber')}
                      fullWidth
                      margin="normal"
                      value={vehicleidentificationnumber}
                      onChange={(e) => setvehicleidentificationnumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregistermasternumber')}
                      fullWidth
                      margin="normal"
                      value={carmasternumber}
                      onChange={(e) => setcarmasternumber(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">

                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregistertypeapproval')}
                      fullWidth
                      margin="normal"
                      value={carregistertypeapproval}
                      onChange={(e) => setcarregistertypeapproval(e.target.value)}
                    />

                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregistermechanicsdescription')}
                      fullWidth
                      multiline={true}
                      rows={3}
                      margin="normal"
                      value={mechanics}
                      onChange={(e) => {
                        const formattedValuemechanics = e.target.value.replace(/ - /g, '\n');
                        setmechanics(formattedValuemechanics);
                      }}
                    />

                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregisterbodydescription')}
                      fullWidth
                      multiline={true}
                      rows={3}
                      margin="normal"
                      value={bodydescription}
                      onChange={(e) => {
                        const formattedValuebody = e.target.value.replace(/ - /g, '\n');
                        setbodydescription(formattedValuebody);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregisterpaintcondition')}
                      fullWidth
                      multiline={true}
                      rows={3}
                      margin="normal"
                      value={paintcondition}
                      onChange={(e) => {
                        const formattedValuepaint = e.target.value.replace(/ - /g, '\n');
                        setpaintcondition(formattedValuepaint);
                      }}
                    />

                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregisterotherdescription')}
                      fullWidth
                      multiline={true}
                      rows={3}
                      margin="normal"
                      value={otherdescription}
                      onChange={(e) => {
                        const formattedValueother = e.target.value.replace(/ - /g, '\n');
                        setotherdescription(formattedValueother);
                      }}
                    />
                  </Grid>
                </Grid>
                <button className='continuebuttonregister' onClick={() => setSelectedSection('documents')}>{t('continuebutton')}</button>
              </>

            )}

            {selectedSection === 'documents' && (
              <>
                <div style={{ marginBottom: 30 }}>
                  <InputLabel>{t('expertreportpdf')}</InputLabel>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handlePdfChange}
                    name="expertReport"
                  />
                </div>
                {imageInputs.map((input, index) => (
                  <div key={input.id} style={{ marginBottom: '20px' }}>
                    <input type="file" multiple onChange={(event) => handleFileChange(index, event)} />
                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                      {input.previews.map((preview, imageIndex) => (
                        <DraggableImage
                          key={imageIndex}
                          preview={preview}
                          index={imageIndex}
                          moveImage={moveImage}
                          handleRemoveImage={() => {
                            setImageInputs((prevInputs) => {
                              const newInputs = [...prevInputs];
                              if (!newInputs[0] || !newInputs[0].previews || !newInputs[0].files) return prevInputs;
                              const previews = [...newInputs[0].previews];
                              const files = [...newInputs[0].files];
                              previews.splice(imageIndex, 1);
                              files.splice(imageIndex, 1);
                              return [{ ...newInputs[0], previews, files }];
                            });
                          }}
                        />
                      ))}
                    </div>
                  </div>
                ))}
                <button className='continuebuttonregister' onClick={() => setSelectedSection('publish')}>{t('continuebutton')}</button>
              </>
            )}

            {selectedSection === 'publish' && (
              <>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t('carregisterminimumprice')}
                      fullWidth
                      margin="normal"
                      type="number"
                      required
                      value={minimumPrice}
                      onChange={(e) => setMinimumPrice(e.target.value)}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel><Trans i18nKey="carregistertobetransported"></Trans></InputLabel>
                      <Select
                        value={transport}
                        onChange={(e) => setTransport(e.target.value)}
                        label="To be transported by the"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                      >
                        <MenuItem value="AG">AG (Aargau)</MenuItem>
                        <MenuItem value="AI">AI (Appenzell Innerrhoden)</MenuItem>
                        <MenuItem value="AR">AR (Appenzell Ausserrhoden)</MenuItem>
                        <MenuItem value="BE">BE (Bern)</MenuItem>
                        <MenuItem value="BL">BL (Basel-Landschaft)</MenuItem>
                        <MenuItem value="BS">BS (Basel-Stadt)</MenuItem>
                        <MenuItem value="FR">FR (Fribourg)</MenuItem>
                        <MenuItem value="GE">GE (Geneva)</MenuItem>
                        <MenuItem value="GL">GL (Glarus)</MenuItem>
                        <MenuItem value="GR">GR (Graubünden)</MenuItem>
                        <MenuItem value="JU">JU (Jura)</MenuItem>
                        <MenuItem value="LU">LU (Lucerne)</MenuItem>
                        <MenuItem value="NE">NE (Neuchâtel)</MenuItem>
                        <MenuItem value="NW">NW (Nidwalden)</MenuItem>
                        <MenuItem value="OW">OW (Obwalden)</MenuItem>
                        <MenuItem value="SG">SG (St. Gallen)</MenuItem>
                        <MenuItem value="SH">SH (Schaffhausen)</MenuItem>
                        <MenuItem value="SO">SO (Solothurn)</MenuItem>
                        <MenuItem value="SZ">SZ (Schwyz)</MenuItem>
                        <MenuItem value="TG">TG (Thurgau)</MenuItem>
                        <MenuItem value="TI">TI (Ticino)</MenuItem>
                        <MenuItem value="UR">UR (Uri)</MenuItem>
                        <MenuItem value="VD">VD (Vaud)</MenuItem>
                        <MenuItem value="VS">VS (Valais)</MenuItem>
                        <MenuItem value="ZG">ZG (Zug)</MenuItem>
                        <MenuItem value="ZH">ZH (Zurich)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="eventid-label">Event *</InputLabel>
                      <Select
                        labelId="eventid-label"
                        id="eventid"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: 'auto',
                              minWidth: 200,
                              maxWidth: 300,
                              height: 'auto'
                            },
                          },
                        }}
                        value={eventid}
                        onChange={(e) => seteventid(e.target.value)}
                        label="Event ID"
                        required
                      >
                        {events.map((event) => (
                          <MenuItem key={event.id} value={event.id}>
                            {`${event.id} - ${new Date(event.startdate).toLocaleDateString()}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
              <TextField
                  label='CODE'
                  fullWidth
                  margin="normal"
                  required
                  value={publishcode}
                  onChange={(e) => setpublishcode(e.target.value)}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid> */}
                </Grid>

                <Button type="submit" variant="contained" style={{ background: 'yellow', color: 'black', borderRadius: 30, float: 'inline-end', border: '1px solid black' }}>
                  {t('publishcar')}
                </Button>
              </>

            )}

            {/* <TextField
            label={t('carregisterspecialequipment')}
            fullWidth
            margin="normal"
            required
            value={specialEquipment}
            onChange={(e) => setSpecialEquipment(e.target.value)}
          />
          <TextField
            label={t('carregisterserialequipment')}
            fullWidth
            margin="normal"
            required
            value={serialEquipment}
            onChange={(e) => setSerialEquipment(e.target.value)}
          /> */}
          </form>
        </Container>
      </div>
    </DndProvider>

  ];
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Admin.css */
.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.user-table th, .user-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.user-table th {
    font-weight: bold;
}

.details-table {
    width: 100%;
    border: none;
    border-collapse: collapse;
}

.details-table td {
    padding: 4px 8px;
    border: none;
}

.details-table td:first-child {
    font-weight: bold;
    width: 150px;
}

.adminusertable {
    width: 80%;
    margin: auto;
}

.adminusertable button:hover {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,WAAW;IACX,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["/* Admin.css */\n.user-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-bottom: 20px;\n}\n\n.user-table th, .user-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n}\n\n.user-table th {\n    font-weight: bold;\n}\n\n.details-table {\n    width: 100%;\n    border: none;\n    border-collapse: collapse;\n}\n\n.details-table td {\n    padding: 4px 8px;\n    border: none;\n}\n\n.details-table td:first-child {\n    font-weight: bold;\n    width: 150px;\n}\n\n.adminusertable {\n    width: 80%;\n    margin: auto;\n}\n\n.adminusertable button:hover {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

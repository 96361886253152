import './footer.css';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function Footer() {
    const {t} = useTranslation();
    
    return (
        <div className='divfooter'>
            <div className='footer'>
                <div>
                    <h5>AutoAuction24</h5>
                    <p>Folge uns auf</p>
                    <div className='socialmediaicons'>
                        <a href="https://www.facebook.com/yourpage" target="_blank" style={{color: 'black'}} rel="noopener noreferrer">
                            <FacebookIcon />
                        </a>
                        <a href="https://www.instagram.com/yourpage" target="_blank" style={{color: 'black'}} rel="noopener noreferrer">
                            <InstagramIcon />
                        </a>
                        <a href="https://www.linkedin.com/in/yourprofile" target="_blank" style={{color: 'black'}} rel="noopener noreferrer">
                            <LinkedInIcon />
                        </a>
                        <a href="https://www.youtube.com/yourchannel" target="_blank" style={{color: 'black'}} rel="noopener noreferrer">
                            <YouTubeIcon />
                        </a>
                    </div>
                    <a style={{color: 'black'}} href="mailto:info@autoauktion24.ch">info@autoauktion24.ch</a>
                </div>
                <div className='aboutsectiontwo'>
                    <a href="/how-it-works?page=instructions"><p>{t('howitworksmenugeneralinstructions')}</p></a>
                    <a href="/how-it-works?page=prices"><p>{t('howitworksmenupricespervehicle')}</p></a>
                    <a href="/how-it-works?page=gc"><p>{t('howitworksmenugentermscondition')}</p></a>
                </div>
                <div className='aboutsection'>
                    <a href="/"><p>{t('auctionMenu')}</p></a>
                    <a href="/how-it-works"><p>{t('howItWorksMenu')}</p></a>
                    <a href="/register"><p>{t('registrationmenu')}</p></a>
                </div>
                <div className='ssl'>
                    <img src='/sslsecure.png' style={{width: '200px'}} alt="SSL Secure"></img>
                </div>
            </div>
        </div>
    )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mui-expanded {
    margin: auto !important;
    margin-bottom: 10px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {
    background: #494944;
    border-radius: 15px;
    color: white;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 2vh;
}

.MuiAccordion-region .MuiAccordionDetails-root {
    background: white;
    color: #1D1E42;
}`, "",{"version":3,"sources":["webpack://./src/components/car/Cartoggleinfo.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,kCAAkC;IAClC,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".Mui-expanded {\n    margin: auto !important;\n    margin-bottom: 10px;\n}\n\n.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {\n    background: #494944;\n    border-radius: 15px;\n    color: white;\n    font-family: 'Poppins', sans-serif;\n    margin-bottom: 2vh;\n}\n\n.MuiAccordion-region .MuiAccordionDetails-root {\n    background: white;\n    color: #1D1E42;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

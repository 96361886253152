// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1dxi1m5 {
    flex-direction: row !important;
    justify-content: center;
    row-gap: 1rem;
    column-gap: 10%;
}

.css-cpgvjg-MuiSnackbar-root {
    left: auto !important;
    right: auto !important;
    top: 20% !important;
}

.compactnumberone {
    /* background: rgb(255 255 0); */
    background: rgb(255 255 163);
    padding-left: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 30px;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/car/Counter.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,uBAAuB;IACvB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;IAChC,4BAA4B;IAC5B,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;IACpB,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".css-1dxi1m5 {\n    flex-direction: row !important;\n    justify-content: center;\n    row-gap: 1rem;\n    column-gap: 10%;\n}\n\n.css-cpgvjg-MuiSnackbar-root {\n    left: auto !important;\n    right: auto !important;\n    top: 20% !important;\n}\n\n.compactnumberone {\n    /* background: rgb(255 255 0); */\n    background: rgb(255 255 163);\n    padding-left: 10px;\n    padding-top: 25px;\n    padding-bottom: 25px;\n    border-radius: 30px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCarById } from '../services/fetchData';
import './EditCar.css';
import Header from '../components/header/Header';
import axios from 'axios';
import config from '../config';
import { useTranslation, Trans } from 'react-i18next';
import { TextField, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import carBrands from './carBrands.json';
import InputAdornment from '@mui/material/InputAdornment';

export default function EditCar() {
  const { id } = useParams(); // Get car ID from route params
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [carName, setCarName] = useState('');
  const [carAccident, setCarAccident] = useState('');
  const [cartype, setcartype] = useState('');
  const [minimumPrice, setMinimumPrice] = useState('');
  const [body, setBody] = useState('');
  const [fuel, setFuel] = useState('');
  const [gear, setGear] = useState('');
  const [firstReg, setFirstReg] = useState('');
  const [mileage, setMileage] = useState('');
  const [interior, setInterior] = useState('');
  const [wheelDrive, setWheelDrive] = useState('');
  const [displacement, setDisplacement] = useState('');
  const [exteriorColour, setExteriorColour] = useState('');
  const [numberOfSeats, setNumberOfSeats] = useState('');
  const [transport, setTransport] = useState('');
  const [eventid, seteventid] = useState('');
  const [events, setEvents] = useState([]);
  const [imageInputs, setImageInputs] = useState([{ id: Date.now(), file: null }]);
  const [specialEquipment, setSpecialEquipment] = useState('');
  const [serialEquipment, setSerialEquipment] = useState('');
  const [vehicleInspection, setVehicleInspection] = useState('');
  const [numberOfKeys, setNumberOfKeys] = useState('');
  const [vehicleRegistrationDocument, setvehicleRegistrationDocument] = useState('');
  const [serviceRecordBooklet, setserviceRecordBooklet] = useState('');
  const [mechanics, setmechanics] = useState('');
  const [carfinish, setcarfinish] = useState('');
  const [carmodel, setCarmodel] = useState('');
  const [vehicleidentificationnumber, setvehicleidentificationnumber] = useState('');
  const [carmasternumber, setcarmasternumber] = useState('');
  const [serviceelectrical, setserviceelectrical] = useState('');
  const [generalcondition, setgeneralcondition] = useState('');
  const [repairamount, setrepairamount] = useState('');
  const [expertReport, setexpertReport] = useState('');

  const [selectedImages, setSelectedImages] = useState([]);
  const [carData, setCarData] = useState({
    cartype: '',
    carAccident: '',
    minimumPrice: '',
    specifications: {}, // State to hold the specifications object
    car_images: [],
  });
  const [message, setMessage] = useState(''); // State for success message
  const [error, setError] = useState(''); // State for error message

  useEffect(() => {
    const getCarDetails = async () => {
      try {
        const data = await fetchCarById(id); // Fetch car details by ID from backend
        setCarName(data.specifications.car_name);
        setCarAccident(data.specifications.car_accident);
        setcartype(data.specifications.car_type);
        setMinimumPrice(data.minimumprice);
        setBody(data.specifications.Body);
        setFuel(data.specifications.Fuel);
        setGear(data.specifications.Gear);
        const firstRegFromApi = data.specifications['1st reg'];
        if (firstRegFromApi) {
          const [month, year] = firstRegFromApi.split('/');
          setFirstReg(`${year}-${month}`);
        }
        setMileage(data.specifications.Mileage);
        setInterior(data.specifications.Interior);
        setExteriorColour(data.specifications.Exterior_colour);
        setNumberOfSeats(data.specifications['Number of seats']);
        setWheelDrive(data.specifications['Wheel drive']);
        setDisplacement(data.specifications.Displacement);
        setcarfinish(data.specifications.carfinish);
        setmechanics(data.specifications.mechanics);
        setNumberOfKeys(data.specifications['Number of seats']);
        setserviceRecordBooklet(data.specifications.serviceRecordBooklet);
        setVehicleInspection(data.specifications.vehicleInspection);
        setvehicleRegistrationDocument(data.specifications.vehicleRegistrationDocument);
        setSpecialEquipment(data.special_equipment);
        setSerialEquipment(data.serial_equipment);
        setTransport(data.specifications['To be transported by the']);
        setvehicleidentificationnumber(data.specifications.vehicleRegistrationDocument);
        setcarmasternumber(data.specifications.carmasternumber);
        setserviceelectrical(data.specifications.serviceelectrical);
        setgeneralcondition(data.specifications.generalcondition);
        setrepairamount(data.specifications.repairamount);
        setCarmodel(data.specifications.carmodel);
      } catch (error) {
        navigate('/');
        // console.error('Error fetching car details:', error);
      }
    };

    getCarDetails();
  }, [id]);

  const handleImageChange = (e) => {
    setSelectedImages([...e.target.files]); // Store selected files
  };

  const handleDeleteImage = async (imagePath) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${config.API_URL}/deletecarimage/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: { image_path: imagePath }, // Send the image path to delete
      });
      setMessage(response.data.message);
      // Remove the deleted image from the carData state
      setCarData((prevState) => ({
        ...prevState,
        car_images: prevState.car_images.filter((image) => image !== imagePath),
      }));
    } catch (err) {
      setError(err.response.data.error || 'An error occurred while deleting the image');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const [year, month] = firstReg.split('-');
    const formattedDate = `${month}/${year}`;

    try {
      const carSpecifications = {
        car_name: carName,
        carmodel: carmodel,
        car_accident: carAccident,
        car_type: cartype,
        Body: body,
        Fuel: fuel,
        Gear: gear,
        "1st reg": formattedDate,
        Mileage: mileage,
        Interior: interior,
        "Wheel drive": wheelDrive,
        Displacement: displacement,
        "Exterior_colour": exteriorColour,
        "Number of seats": numberOfSeats,
        "To be transported by the": transport,
        "vehicleInspection": vehicleInspection,
        "numberOfKeys": numberOfKeys,
        "vehicleRegistrationDocument": vehicleRegistrationDocument,
        "serviceRecordBooklet": serviceRecordBooklet,
        "mechanics": mechanics,
        "carfinish": carfinish,
        "vehicleidentificationnumber": vehicleidentificationnumber,
        "carmasternumber": carmasternumber,
        "serviceelectrical": serviceelectrical,
        "generalcondition": generalcondition,
        "repairamount": repairamount
      };

      const formData = new FormData();
      formData.append('car_specifications', JSON.stringify(carSpecifications));
      formData.append('special_equipment', specialEquipment)
      formData.append('serial_equipment', serialEquipment)
      formData.append('minimum_price', minimumPrice);
      for (const image of selectedImages) {
        formData.append('images', image);
      }
      try {
        const token = localStorage.getItem('token');
  
        const response = await axios.put(`${config.API_URL}/updatecarinfo/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
  
        setMessage(response.data.message);
        navigate(`/mycars/`);
      } catch (err) {
        setError(err.response.data.error || 'An error occurred while updating the car');
      }

    } catch (error) {
      console.error('Failed to register car:', error);
    }
  };

  return (
    <>
      <Header />
      <div className="editcar-container">
        <h2>Edit Car</h2>
        <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal" required>
            <InputLabel><Trans i18nKey="carregistercarbrand"></Trans></InputLabel>
            <Select
                value={carName}
                onChange={(e) => setCarName(e.target.value)}
                label="Car Brand"
            >
                {carBrands.map((brand, index) => (
                    <MenuItem key={index} value={brand}>
                        {brand}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    <TextField
          label={t('carregistermodel')}
          fullWidth
          margin="normal"
          required
          value={carmodel}
          onChange={(e) => setCarmodel(e.target.value)}
        />

        <FormControl fullWidth margin="normal" required>
            <InputLabel><Trans i18nKey="carregistercaraccident"></Trans></InputLabel>
            <Select
                value={carAccident}
                onChange={(e) => setCarAccident(e.target.value)}
                label={t('carregisteraccident')}
            >
                <MenuItem value="yes"><Trans i18nKey="carregistercaraccidentyes"></Trans></MenuItem>
                <MenuItem value="no"><Trans i18nKey="carregistercaraccidentno"></Trans></MenuItem>
            </Select>
        </FormControl>
        <TextField
          label={t('carregistertype')}
          fullWidth
          margin="normal"
          required
          value={cartype}
          onChange={(e) => setcartype(e.target.value)}
        />
        <TextField
          label={t('carregisterrepairsamount')}
          fullWidth
          margin="normal"
          type="number"
          required
          value={repairamount}
          onChange={(e) => setrepairamount(e.target.value)}
          InputProps={{
            inputProps: { min: 0 },
          }}
        />
        <TextField
          label={t('carregisterminimumprice')}
          fullWidth
          margin="normal"
          type="number"
          required
          value={minimumPrice}
          onChange={(e) => setMinimumPrice(e.target.value)}
          InputProps={{
            inputProps: { min: 0 },
          }}
        />
        <FormControl fullWidth margin="normal" required>
            <InputLabel><Trans i18nKey="carregisterbody"></Trans></InputLabel>
            <Select
                value={body}
                onChange={(e) => setBody(e.target.value)}
                label="Body"
            >
                <MenuItem value="Limousine"><Trans i18nKey="carregisterbodylimousine"></Trans></MenuItem>
                <MenuItem value="Estate car"><Trans i18nKey="carregisterbodyestatecar"></Trans></MenuItem>
                <MenuItem value="Convertible"><Trans i18nKey="carregisterbodyconvertible"></Trans></MenuItem>
                <MenuItem value="Coupe"><Trans i18nKey="carregisterbodycoupe"></Trans></MenuItem>
                <MenuItem value="Hatchback"><Trans i18nKey="carregisterbodyhatchback"></Trans></MenuItem>
                <MenuItem value="SUV/Offroader"><Trans i18nKey="carregisterbodysuvoffroader"></Trans></MenuItem>
                <MenuItem value="Van"><Trans i18nKey="carregisterbodyvan"></Trans></MenuItem>
                <MenuItem value="Roadster"><Trans i18nKey="carregisterbodyroadster"></Trans></MenuItem>
                <MenuItem value="Pickup"><Trans i18nKey="carregisterbodypickup"></Trans></MenuItem>
            </Select>
        </FormControl>
          <FormControl fullWidth margin="normal" required>
            <InputLabel><Trans i18nKey="carregisterfuel"></Trans></InputLabel>
            <Select
                value={fuel}
                onChange={(e) => setFuel(e.target.value)}
                label="Fuel"
            >
                <MenuItem value="Petrol"><Trans i18nKey="carregisterfuelpetrol"></Trans></MenuItem>
                <MenuItem value="Diesel"><Trans i18nKey="carregisterfueldiesel"></Trans></MenuItem>
                <MenuItem value="Electrical"><Trans i18nKey="carregisterfuelelectrical"></Trans></MenuItem>
                <MenuItem value="Gas (CNG)"><Trans i18nKey="carregisterfuelgascng"></Trans></MenuItem>
                <MenuItem value="Hybrid"><Trans i18nKey="carregisterfuelhybrid"></Trans></MenuItem>
                <MenuItem value="Gas (LPG)"><Trans i18nKey="carregisterfuelgaslpg"></Trans></MenuItem>
                <MenuItem value="Hybrid/petrol"><Trans i18nKey="carregisterfuelhybridpetrol"></Trans></MenuItem>
                <MenuItem value="Hybrid/diesel"><Trans i18nKey="carregisterfuelhybriddiesel"></Trans></MenuItem>
                <MenuItem value="Hydrogen"><Trans i18nKey="carregisterfuelhydrogen"></Trans></MenuItem>
            </Select>
        </FormControl>
        <FormControl fullWidth margin="normal" required>
          <InputLabel><Trans i18nKey="carregistergear"></Trans></InputLabel>
          <Select
              value={gear}
              onChange={(e) => setGear(e.target.value)}
              label="Gear"
          >
              <MenuItem value="Manual"><Trans i18nKey="carregistergearmanual"></Trans></MenuItem>
              <MenuItem value="Automatic"><Trans i18nKey="carregistergearautomatic"></Trans></MenuItem>
              <MenuItem value="Semi automatic"><Trans i18nKey="carregistergearsemiautomatic"></Trans></MenuItem>
          </Select>
      </FormControl>
        <TextField
          label={t('carregisterfirstreg')}
          fullWidth
          margin="normal"
          required
          value={firstReg}
          onChange={(e) => setFirstReg(e.target.value)}
          type="month"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label={t('carregistervehicleidentificationnumber')}
          fullWidth
          margin="normal"
          value={vehicleidentificationnumber}
          onChange={(e) => setvehicleidentificationnumber(e.target.value)}
        />
        <TextField
          label={t('carregistermasternumber')}
          fullWidth
          margin="normal"
          value={carmasternumber}
          onChange={(e) => setcarmasternumber(e.target.value)}
        />
        <TextField
          label={t('carregistermileage')}
          fullWidth
          margin="normal"
          required
          value={mileage}
          onChange={(e) => setMileage(e.target.value)}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
          }}
        />

        <FormControl fullWidth margin="normal" required>
            <InputLabel><Trans i18nKey="carregisterinterior"></Trans></InputLabel>
            <Select
                value={interior}
                onChange={(e) => setInterior(e.target.value)}
                label="Interior"
            >
                <MenuItem value="Black"><Trans i18nKey="carregisterinteriorblack"></Trans></MenuItem>
                <MenuItem value="Beige"><Trans i18nKey="carregisterinteriorbeige"></Trans></MenuItem>
                <MenuItem value="Gray"><Trans i18nKey="carregisterinteriorgray"></Trans></MenuItem>
                <MenuItem value="Brown"><Trans i18nKey="carregisterinteriorbrown"></Trans></MenuItem>
                <MenuItem value="Red"><Trans i18nKey="carregisterinteriorred"></Trans></MenuItem>
                <MenuItem value="Blue"><Trans i18nKey="carregisterinteriorblue"></Trans></MenuItem>
                <MenuItem value="White"><Trans i18nKey="carregisterinteriorwhite"></Trans></MenuItem>
                <MenuItem value="Green"><Trans i18nKey="carregisterinteriorgreen"></Trans></MenuItem>
                <MenuItem value="Tan"><Trans i18nKey="carregisterinteriortan"></Trans></MenuItem>
                <MenuItem value="Cream"><Trans i18nKey="carregisterinteriorcream"></Trans></MenuItem>
            </Select>
        </FormControl>

        <FormControl fullWidth margin="normal" required>
          <InputLabel><Trans i18nKey="carregisterwheeldrive"></Trans></InputLabel>
          <Select
              value={wheelDrive}
              onChange={(e) => setWheelDrive(e.target.value)}
              label="Wheel drive"
          >
              <MenuItem value="Front"><Trans i18nKey="carregisterwheeldrivefront"></Trans></MenuItem>
              <MenuItem value="Rear"><Trans i18nKey="carregisterwheeldriverear"></Trans></MenuItem>
              <MenuItem value="4x4"><Trans i18nKey="carregisterwheeldrive4x4"></Trans></MenuItem>
          </Select>
      </FormControl>

      <TextField
        label={t('carregisterdisplacement')}
        fullWidth
        margin="normal"
        required
        value={displacement}
        onChange={(e) => setDisplacement(e.target.value)}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">liters</InputAdornment>,
          inputProps: { min: 0 }, // Optional: Prevent negative numbers
        }}
      />
      <FormControl fullWidth margin="normal" required>
          <InputLabel><Trans i18nKey="carregisterexteriorcolour"></Trans></InputLabel>
          <Select
              value={exteriorColour}
              onChange={(e) => setExteriorColour(e.target.value)}
              label="Exterior colour"
          >
              <MenuItem value="Black"><Trans i18nKey="carregisterexteriorcolourblack"></Trans></MenuItem>
              <MenuItem value="White"><Trans i18nKey="carregisterexteriorcolourwhite"></Trans></MenuItem>
              <MenuItem value="Red"><Trans i18nKey="carregisterexteriorcolourred"></Trans></MenuItem>
              <MenuItem value="Blue"><Trans i18nKey="carregisterexteriorcolourblue"></Trans></MenuItem>
              <MenuItem value="Green"><Trans i18nKey="carregisterexteriorcolourgreen"></Trans></MenuItem>
              <MenuItem value="Silver"><Trans i18nKey="carregisterexteriorcoloursilver"></Trans></MenuItem>
              <MenuItem value="Gray"><Trans i18nKey="carregisterexteriorcolourgray"></Trans></MenuItem>
              <MenuItem value="Yellow"><Trans i18nKey="carregisterexteriorcolouryellow"></Trans></MenuItem>
              <MenuItem value="Orange"><Trans i18nKey="carregisterexteriorcolourorange"></Trans></MenuItem>
              <MenuItem value="Brown"><Trans i18nKey="carregisterexteriorcolourbrown"></Trans></MenuItem>
          </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" required>
        <InputLabel><Trans i18nKey="carregisternumberofseats"></Trans></InputLabel>
        <Select
            value={numberOfSeats}
            onChange={(e) => setNumberOfSeats(e.target.value)}
            label="Number of seats"
        >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
        </Select>
    </FormControl>

      <FormControl fullWidth margin="normal" required>
        <InputLabel><Trans i18nKey="carregistertobetransported"></Trans></InputLabel>
        <Select
            value={transport}
            onChange={(e) => setTransport(e.target.value)}
            label="To be transported by the"
        >
          <MenuItem value="AG">AG (Aargau)</MenuItem>
          <MenuItem value="AI">AI (Appenzell Innerrhoden)</MenuItem>
          <MenuItem value="AR">AR (Appenzell Ausserrhoden)</MenuItem>
          <MenuItem value="BE">BE (Bern)</MenuItem>
          <MenuItem value="BL">BL (Basel-Landschaft)</MenuItem>
          <MenuItem value="BS">BS (Basel-Stadt)</MenuItem>
          <MenuItem value="FR">FR (Fribourg)</MenuItem>
          <MenuItem value="GE">GE (Geneva)</MenuItem>
          <MenuItem value="GL">GL (Glarus)</MenuItem>
          <MenuItem value="GR">GR (Graubünden)</MenuItem>
          <MenuItem value="JU">JU (Jura)</MenuItem>
          <MenuItem value="LU">LU (Lucerne)</MenuItem>
          <MenuItem value="NE">NE (Neuchâtel)</MenuItem>
          <MenuItem value="NW">NW (Nidwalden)</MenuItem>
          <MenuItem value="OW">OW (Obwalden)</MenuItem>
          <MenuItem value="SG">SG (St. Gallen)</MenuItem>
          <MenuItem value="SH">SH (Schaffhausen)</MenuItem>
          <MenuItem value="SO">SO (Solothurn)</MenuItem>
          <MenuItem value="SZ">SZ (Schwyz)</MenuItem>
          <MenuItem value="TG">TG (Thurgau)</MenuItem>
          <MenuItem value="TI">TI (Ticino)</MenuItem>
          <MenuItem value="UR">UR (Uri)</MenuItem>
          <MenuItem value="VD">VD (Vaud)</MenuItem>
          <MenuItem value="VS">VS (Valais)</MenuItem>
          <MenuItem value="ZG">ZG (Zug)</MenuItem>
          <MenuItem value="ZH">ZH (Zurich)</MenuItem>
        </Select>
    </FormControl>

        <TextField
          label={t('carregisterspecialequipment')}
          fullWidth
          margin="normal"
          required
          value={specialEquipment}
          onChange={(e) => setSpecialEquipment(e.target.value)}
        />
        <TextField
          label={t('carregisterserialequipment')}
          fullWidth
          margin="normal"
          required
          value={serialEquipment}
          onChange={(e) => setSerialEquipment(e.target.value)}
        />
        <TextField
          label={t('carregistervehicleinspection')}
          fullWidth
          margin="normal"
          required
          value={vehicleInspection}
          onChange={(e) => setVehicleInspection(e.target.value)}
        />
      <FormControl fullWidth margin="normal" required>
          <InputLabel><Trans i18nKey="carregisterservicerecordbooklet"></Trans></InputLabel>
          <Select
              value={serviceRecordBooklet}
              onChange={(e) => setserviceRecordBooklet(e.target.value)}
              label="Service record booklet"
          >
              <MenuItem value="Available"><Trans i18nKey="carregisterservicerecordbookletavailable"></Trans></MenuItem>
              <MenuItem value="Incomplete"><Trans i18nKey="carregisterservicerecordbookletincomplete"></Trans></MenuItem>
              <MenuItem value="Missing"><Trans i18nKey="carregisterservicerecordbookletmissing"></Trans></MenuItem>
          </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" required>
          <InputLabel><Trans i18nKey="carregisterserviceelectrical"></Trans></InputLabel>
          <Select
              value={serviceelectrical}
              onChange={(e) => setserviceelectrical(e.target.value)}
              label="Service Electrical"
          >
              <MenuItem value="Available"><Trans i18nKey="carregisterserviceelectricalavailable"></Trans></MenuItem>
              <MenuItem value="Incomplete"><Trans i18nKey="carregisterserviceelectricalincomplete"></Trans></MenuItem>
              <MenuItem value="Missing"><Trans i18nKey="carregisterservicerecordelectricalmissing"></Trans></MenuItem>
          </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" required>
          <InputLabel><Trans i18nKey="carregistergeneralcondition"></Trans></InputLabel>
          <Select
              value={generalcondition}
              onChange={(e) => setgeneralcondition(e.target.value)}
              label="General Condition"
          >
              <MenuItem value="Hail Damage"><Trans i18nKey="carregistergeneralconditionhaildamage"></Trans></MenuItem>
              <MenuItem value="Dog Owner"><Trans i18nKey="carregistergeneralconditiondogowner"></Trans></MenuItem>
              <MenuItem value="Smoker Vehicle"><Trans i18nKey="carregistergeneralconditionsmokervehicle"></Trans></MenuItem>
          </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" required>
          <InputLabel><Trans i18nKey="carregistervehicleregistrationdocument"></Trans></InputLabel>
          <Select
              value={vehicleRegistrationDocument}
              onChange={(e) => setvehicleRegistrationDocument(e.target.value)}
              label="Vehicle registration document"
          >
              <MenuItem value="Available"><Trans i18nKey="carregistercarregistervehicleavailable"></Trans></MenuItem>
              <MenuItem value="Will provide later"><Trans i18nKey="carregistercarregistervehiclewillprovidelater"></Trans></MenuItem>
          </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" required>
        <InputLabel><Trans i18nKey="carregisternumberofkeys"></Trans></InputLabel>
        <Select
            value={numberOfKeys}
            onChange={(e) => setNumberOfKeys(e.target.value)}
            label="Number of keys"
        >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
        </Select>
    </FormControl>

        <TextField
          label={t('carregistermechanics')}
          fullWidth
          margin="normal"
          required
          value={mechanics}
          onChange={(e) => setmechanics(e.target.value)}
        />
        <FormControl fullWidth margin="normal" required>
            <InputLabel><Trans i18nKey="carregistercarfinish"></Trans></InputLabel>
            <Select
                value={carfinish}
                onChange={(e) => setcarfinish(e.target.value)}
                label="Car finish"
            >
                <MenuItem value="Bubble effect"><Trans i18nKey="carregistercarfinisbubbleeffect"></Trans></MenuItem>
                <MenuItem value="Individual paint"><Trans i18nKey="carregistercarfinishindividualpaint"></Trans></MenuItem>
                <MenuItem value="Matt paint"><Trans i18nKey="carregistercarfinishmattpaint"></Trans></MenuItem>
                <MenuItem value="Metallic"><Trans i18nKey="carregistercarfinishmetallic"></Trans></MenuItem>
                <MenuItem value="Solid paint"><Trans i18nKey="carregistercarfinishsolidpaint"></Trans></MenuItem>
            </Select>
        </FormControl>


          {/* Car Images */}
          <div className="car-images">
            <label>Car Images</label>
            {carData.car_images.map((image, index) => (
              <div key={index} className="image-container">
                <img src={image} alt={`Car ${index + 1}`} style={{ width: '100px', margin: '10px' }} />
                <Button variant="outlined" color="error" onClick={() => handleDeleteImage(image)}>
                  Delete
                </Button>
              </div>
            ))}
          </div>
          <input type="file" multiple onChange={handleImageChange} />
          <Button type="submit" variant="contained" color="primary">
            Save Changes
          </Button>
        </form>

        {/* Display success or error message */}
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
    </>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftside {
    font-family: 'Poppins', sans-serif;
}

.rightside {
    padding: '20px';
    width: 60%;
    margin-left: 5%;
}

.howitworksdiv {
    display: flex;
    width: 80%;
    margin: auto;
}

@media (max-width: 768px) {
    .howitworksdiv {
        display: grid;
        width: 80%;
        margin: auto;
        margin-top: 10%;
    }

    .rightside {
        margin-left: 0%;
        width: auto;
    }

    .nav-links.open {
        z-index: 10;
    }

    .navbar-right.open {
        z-index: 11;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Howitworks.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;AACtC;;AAEA;IACI,eAAe;IACf,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;QACb,UAAU;QACV,YAAY;QACZ,eAAe;IACnB;;IAEA;QACI,eAAe;QACf,WAAW;IACf;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,WAAW;IACf;EACF","sourcesContent":[".leftside {\n    font-family: 'Poppins', sans-serif;\n}\n\n.rightside {\n    padding: '20px';\n    width: 60%;\n    margin-left: 5%;\n}\n\n.howitworksdiv {\n    display: flex;\n    width: 80%;\n    margin: auto;\n}\n\n@media (max-width: 768px) {\n    .howitworksdiv {\n        display: grid;\n        width: 80%;\n        margin: auto;\n        margin-top: 10%;\n    }\n\n    .rightside {\n        margin-left: 0%;\n        width: auto;\n    }\n\n    .nav-links.open {\n        z-index: 10;\n    }\n\n    .navbar-right.open {\n        z-index: 11;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

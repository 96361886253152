// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainheader {
    display: flex;
    margin: auto;
    flex-direction: column;
    width: 80%;
}

@media (max-width: 480px) {
    .mainheader {
        margin-top: 10vh;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Resetpassword.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".mainheader {\n    display: flex;\n    margin: auto;\n    flex-direction: column;\n    width: 80%;\n}\n\n@media (max-width: 480px) {\n    .mainheader {\n        margin-top: 10vh;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

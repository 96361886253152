// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 480px) {
    .myAccount {
        margin-top: 10vh;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Myaccount.css"],"names":[],"mappings":"AAAA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["@media (max-width: 480px) {\n    .myAccount {\n        margin-top: 10vh;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 480px) {
    .myAccount {
        margin-top: 10vh;
    }
}


.myaccountform .css-mhc70k-MuiGrid-root {
    margin-top: unset !important;
    -webkit-flex-wrap: wrap !important;
    column-gap: unset !important;
}

.myaccountform .MuiGrid-root.MuiGrid-item {
    border: unset !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Myaccount.css"],"names":[],"mappings":"AAAA;IACI;QACI,gBAAgB;IACpB;AACJ;;;AAGA;IACI,4BAA4B;IAC5B,kCAAkC;IAClC,4BAA4B;AAChC;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":["@media (max-width: 480px) {\n    .myAccount {\n        margin-top: 10vh;\n    }\n}\n\n\n.myaccountform .css-mhc70k-MuiGrid-root {\n    margin-top: unset !important;\n    -webkit-flex-wrap: wrap !important;\n    column-gap: unset !important;\n}\n\n.myaccountform .MuiGrid-root.MuiGrid-item {\n    border: unset !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

export default function Dpr() {
    return(
        <>
            <div style={{fontFamily: 'Poppins'}}>
                <h2>Data protection regulation (03.2020)</h2>
                <p>1. AutoAuction24 attaches great importance to the protection of the personal rights of users when using the auction platform. The following explanations describe the handling of personal data and measures to achieve data protection compliance.</p>
                <p>2. Personal data within the scope of these regulations are all details about the circumstances of a specific person. Processing means any handling of such data, including in particular the disclosure or deletion, use, collection, storage, transmission or administration of personal data.</p>
                <p>3. Data is collected solely in connection with the use of the AutoAuction24 auction platform using the electronic tools provided for this purpose and always in compliance with the applicable Swiss data protection law.</p>
             </div>
        </>
    )
}
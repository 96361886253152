import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';  // Import useNavigate
import config from '../config';
import Header from '../components/header/Header';
import './Resetpassword.css';
import Spinner from '../components/spinner';

function ResetPassword() {
  const { token } = useParams(); 
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await fetch(`${config.API_URL}/reset_password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, password })
      });
      const data = await response.json();
      
      if (response.ok) {
        setLoading(false);
        // If the password was successfully reset, redirect to homepage
        setMessage('Password successfully changed! Redirecting...');
        setTimeout(() => {
          navigate('/');  // Redirect to homepage after 2 seconds
        }, 2000);
      } else {
        setLoading(false);
        setMessage(data.message || 'Error resetting password');
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <Header />
      <Spinner loading={loading} />
      <div className='mainheader'>
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter new password"
            required
          />
          <button type="submit" style={{ background: '#1D1E42', fontFamily: 'Poppins', fontWeight: 'bold', color: 'white', borderRadius: 30 }}>Reset Password</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </>
  );
}

export default ResetPassword;

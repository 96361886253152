import React from "react";
import Header from "../components/header/Header";
import Bidlist from "../components/homepage/bidlist";
import Banner from "../components/homepage/banner";

export default function Home() {
  return (
    <>
      <Header />
      <Banner />
      <Bidlist />
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/header/Header';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CarImageGallery from '../components/car/carimagegallery';
import './Car.css'; // Import the CSS file for styles
import { fetchCarData } from '../services/fetchData';
import CheckoutButton from './checkoutbutton';
import './Mycar.css';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cartoggleinfo from '../components/car/cartoggleinfo';

export default function MyCar() {
  const { id } = useParams();
  const [car, setCar] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation();


  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  
    const getCarData = async () => {
      const data = await fetchCarData(id);
      
      // Directly set the car state to the fetched data
      if (data && data.id) {
        setCar(data);
      } else {
        console.error("Car not found or invalid data received");
        navigate('/')
        // setCar(null); // Optionally handle the case where the car is not found
      }
    };
  
    getCarData();
  }, [id]);

  const accordionData = [
    {
      title: t('vehicleDescriptionTitle'),
      details: {
        columns: [
          [
            `${t('repairsTitle')} - ${car?.specifications?.repairamount || ''}`,
            `${t('vehicleInspection')} - ${car?.specifications?.vehicleInspection || ''}`,
          ],
          [
            `${t('serviceRecordBooklet')} - ${car?.specifications?.serviceRecordBooklet || ''}`,
            `${t('vehicleRegistrationDocument')} - ${car?.specifications?.vehicleRegistrationDocument || ''}`,
            `${t('numberOfKeys')} - ${car?.specifications?.numberOfKeys || ''}`,
          ],
          [
            `Model - ${car?.specifications?.carmodel || ''}`
          ]
        ]
      },
      expandIcon: "ArrowDropDownIcon"
    },
    {
      title: t('conditionTitle'),
      details: {
        columns: [
          [
            `${t('mechanics')} - ${car?.specifications?.mechanics || ''}`,
            `${t('carFinish')} - ${car?.specifications?.carfinish || ''}`,
            `${t('bodyTitle')} - ${car?.specifications?.Body || ''}`,
          ]
        ]
      },
      expandIcon: "ArrowDropDownIcon"
    }
  ];

  if (!car) {
    return <div>Loading...</div>;
  }

  const postInNextEvent = (id) => {
    console.log(`Sending car ID: ${id}`);
    const token = localStorage.getItem('token');
    fetch(`${config.API_URL}/posttonextevent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ car_id: id })
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        window.location.reload();
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  

  // Determine if the event is completed
  const isEventCompleted = car.enddate && new Date(car.enddate) < new Date();

  // List of keys to exclude from displaying in the table
  const excludeKeys = [
    'car_name',
    'carmodel',
    'car_accident',
    'interior',
    'wheel drive',
    'displacement',
    'exterior_colour',
    'number of seats',
    'vehicleinspection',
    'numberofkeys',
    'vehicleregistrationdocument',
    'servicerecordbooklet',
    'mechanics',
    'carfinish',
    'vehicleidentificationnumber',
    'carmasternumber',
    'serviceelectrical',
    'generalcondition',
    'repairamount',
    'registercartype',
    'interiortype'
  ];

  const specifications = car.specifications && typeof car.specifications === 'string' 
    ? JSON.parse(car.specifications) 
    : car.specifications;

  return (
    <>
      <Header />
      <div className="mainheaderh3">
        <h3>
          {specifications.car_name}, {specifications.Body}, {specifications['1st reg']}
        </h3>
      </div>
      <div className="car-container">
        <div className="car-column">
          <CarImageGallery carId={id} />
        </div>
        <div className="car-column">
          <TableContainer component={Paper}>
            <Table aria-label="car details table">
              <TableHead>
                <TableRow style={{ backgroundColor: '#f3f3f3' }}>
                  <TableCell>Specification</TableCell>
                  <TableCell align="right">Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {specifications &&
                  Object.entries(specifications)
                    .filter(([key]) => !excludeKeys.includes(key.toLowerCase()))
                    .map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row" style={{ paddingLeft: '2%', lineHeight: '1' }}>
                          {key.replace(/_/g, ' ').toUpperCase()}
                        </TableCell>
                        <TableCell align="right">{value}</TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="car-column">
          {isLoggedIn ? (
            <>
              <div style={{ display: 'flex', columnGap: 10 }}>
                {car.ispaid === 0 && <CheckoutButton carId={id} />}
                <p>Minimum Price</p>
                <p>{car.minimumprice !== null ? car.minimumprice : 'N/A'} EURO</p>
              </div>
              <div>
                <p>Highest bid amount {car.currentbidprice !== null ? car.currentbidprice : 'N/A'} EURO</p>
              </div>
              <div style={{ display: 'flex', columnGap: 10 }}>
                <p style={{ fontWeight: 'bold' }}>{car.status === 1 ? 'Sold' : 'Not Sold'}</p>
              </div>
              <div>
                <p>Actual Event {car.eventid}, Status of event {isEventCompleted ? 'Event completed' : 'Active'}</p>
                {isEventCompleted && <button onClick={() => postInNextEvent(id)}>Post in next event</button>}
              </div>
            </>
          ) : (
            <p>Please login to see the price</p>
          )}
        </div>
      </div>
      <Cartoggleinfo accordionData={accordionData} />
    </>
  );
}

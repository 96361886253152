import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/header/Header';
import Counter from '../components/car/counter';
import { fetchData, fetchDataSpecifications } from '../services/fetchData';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import io from 'socket.io-client';
import CarImageGallery from '../components/car/carimagegallery';
import './Car.css';
import CountDown from '../components/homepage/countdown';
import Cartoggleinfo from '../components/car/cartoggleinfo';
import { useTranslation } from 'react-i18next';
import config from '../config';

const socket = io('https://autoauction24.ch', {
  path: '/api/socket.io'
});

export default function Car() {
  const { id } = useParams();
  const [car, setCar] = useState(null);
  const [carspecifications, setcarspecifications] = useState(null);
  const [currentBidPrice, setCurrentBidPrice] = useState(null);
  const [currentWinner, setCurrentWinner] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { t } = useTranslation();

  const accordionData = [
    {
      title: t('vehicleDescriptionTitle'),
      details: {
        columns: [
          [
            `${t('repairsTitle')} - ${car?.specifications?.repairamount || ''}`,
            `${t('carregistervehicleinspection')} - ${car?.specifications?.vehicleInspection || ''}`,
            `${t('accidentTitle')} - ${car?.specifications?.car_accident || ''}`,
          ],
          [
            `${t('serviceRecordBooklet')} - ${car?.specifications?.serviceRecordBooklet || ''}`,
            `${t('vehicleRegistrationDocument')} - ${car?.specifications?.Fahrzeugausweis || ''}`,
            `${t('numberOfKeys')} - ${car?.specifications?.numberOfKeys || ''}`,
          ],
          [
            `${t('carregistertypeapproval')} - ${car?.specifications?.carregistertypeapproval || ''}`,
            `${t('carregistervehicleidentificationnumber')} - ${car?.specifications?.vehicleidentificationnumber || ''}`,
            `${t('carregistermasternumber')} - ${car?.specifications?.carmasternumber || ''}`,
          ]
        ]
      },
      expandIcon: "ArrowDropDownIcon"
    },
    {
      title: t('conditionTitle'),
      details: {
        columns: [
          [
            `${t('mechanik')} - ${car?.specifications?.mechanics || ''}`,
            `${t('lack')} - ${car?.specifications?.paintcondition || ''}`,
            `${t('carosserie')} - ${car?.specifications?.bodydescription || ''}`,
            `${t('carregisterotherdescription')} - ${car?.specifications?.otherdescription || ''}`,
          ]
        ]
      }
    }
  ];


  // Define handleBidUpdates outside of useEffect
  const handleBidUpdates = async () => {
    console.log('Bid placed. Handling bid update...');
    // Re-fetch car data
    const data = await fetchData();
    const carData = data[0].cars.find((c) => c.id === parseInt(id));
    setCar(carData);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);

    const getCarData = async () => {
      const data = await fetchData();
      const dataspecifications = await fetchDataSpecifications(id);
      setcarspecifications(dataspecifications);
      setStartDate(data[0].startdate);
      setEndDate(data[0].enddate);
      const carData = data[0].cars.find((c) => c.id === parseInt(id));
      setCar(carData);
    };

    socket.connect();

    socket.on('connect', () => {
      console.log('Connected to Socket.IO server');
    });

    const handleBidUpdate = (data) => {
      console.log('New price updated:', data.bid);
      console.log('Updated by:', data.updated_by);
      setCurrentBidPrice(data.bid);
      setCurrentWinner(data.updated_by);
      handleBidUpdates(); // Call handleBidUpdates to refresh car data
    };

    socket.on('bid_update', handleBidUpdate);

    getCarData();

    return () => {
      socket.off('bid_update', handleBidUpdate);
      socket.disconnect();
    };
  }, [id]);

  if (!car) {
    return <div>Loading...</div>;
  }


  const handleExpertReportClick = async () => {
    try {
      const response = await fetch(`${config.API_URL}/expertreport/${car.id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      window.location.assign(`${config.API_URL}/expertreport/${car.id}`, '_blank');
    } catch (error) {
      console.error('Error fetching expert report:', error);
      alert("Failed to fetch expert report.");
    }
  };

  return (
    <>
      <Header />
      <div className='mainheaderh3'>
        <h3 style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>{car.specifications.car_name} {car.specifications.carmodel}, {car.specifications.car_type}PS</h3>
        {/* <p>Article No: {id}</p> */}
        <p>{t('articleNumber')} {id}</p>
      </div>
      <div className="car-container">
        <div className="car-column">
          <CarImageGallery carId={id} />
          {car.expertreport && (
            <button onClick={handleExpertReportClick}>
              Expert Report
            </button>
          )}
        </div>
        <div className="car-column">
          <TableContainer component={Paper}>
            <Table aria-label="car details table">
              <TableHead>
                <TableRow style={{ backgroundColor: '#f3f3f3' }}>
                  <TableCell style={{ borderTopLeftRadius: 30, background: '#efefe3', color: 'black', fontFamily: 'Poppins', fontWeight: 'bold' }}>{t('carTableSpecifications')}</TableCell>
                  <TableCell style={{ borderTopRightRadius: 30, background: '#efefe3', color: 'black', fontFamily: 'Poppins', fontWeight: 'bold' }} align="right">{t('carDetailsSpecifications')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carspecifications && Object.entries(carspecifications)
                  .map(([key, spec]) => {
                    // Normalize the key for display
                    const displayKey = key.replace('_', ' ').toUpperCase();

                    // Extract the value and image_url
                    const value = spec.value || 'N/A';
                    const imageUrl = `/${spec.image_url}`;

                    return (
                      <TableRow key={key} style={{ fontFamily: 'Poppins' }}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '2%',
                            lineHeight: '1',
                            fontFamily: 'Poppins',
                            fontWeight: 'bold',
                          }}
                        >
                          {/* Image Icon */}
                          <img
                            src={imageUrl}
                            alt={displayKey}
                            style={{
                              width: '24px', // Adjust size as needed
                              height: '24px',
                              marginRight: '10px', // Space between image and text
                            }}
                          />
                          {/* Display the translated key */}
                          {t(displayKey).toUpperCase()}
                        </TableCell>
                        <TableCell style={{ fontFamily: 'Poppins' }} align="right">
                          {/* Display value */}
                          {value}
                        </TableCell>
                      </TableRow>
                    );
                  })}


              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="car-column">
          {isLoggedIn ? (
            <>
              <div>
                {car.currentbidprice > 0 ? (
                  <>
                    <div className='countdownincarevent'>
                      <CountDown targetDate={startDate} endTargetDate={endDate} />
                    </div>
                    <Counter
                      car={car}
                      currentBidPrice={currentBidPrice}
                      minimumprice={car.minimumprice}
                      onBidUpdate={handleBidUpdates}
                    />
                    <p style={{ fontFamily: 'Poppins', textAlign: 'center' }}>{t('inclusiveTax')}</p>
                    <p style={{ fontFamily: 'Poppins', textAlign: 'center' }}>{t('highestBidPlacedBy')} <span style={{ fontWeight: 'bold' }}>{currentWinner ? currentWinner : car.bidwinner}</span></p>
                  </>
                ) : (
                  <CountDown targetDate={startDate} endTargetDate={endDate} />
                )}
              </div>
            </>
          ) : (
            <>
              <div className='insidecarpage'>
                <CountDown targetDate={startDate} endTargetDate={endDate} />
              </div>
              <p style={{ width: '80%', margin: 'auto' }}>{t('loginToSeePrice')}</p>
            </>
          )}
        </div>
      </div>
      <Cartoggleinfo accordionData={accordionData} />
    </>
  );
}

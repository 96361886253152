// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eventcountdown {
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    width: 80%;
    margin: auto;
    margin-bottom: 4vh;
}`, "",{"version":3,"sources":["webpack://./src/components/homepage/Countdown.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kCAAkC;IAClC,eAAe;IACf,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".eventcountdown {\n    color: black;\n    font-family: 'Poppins', sans-serif;\n    font-size: 20px;\n    width: 80%;\n    margin: auto;\n    margin-bottom: 4vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

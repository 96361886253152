import './Banner.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

export default function Banner() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        setIsLoggedIn(!!token);
    }, []);

    const handleButtonClick = () => {
        if (isLoggedIn) {
            navigate('/registercar');
        } else {
            navigate('/register');
        }
    };

    return (
        <div className="container-fluid bannerdiv">
            <div className="row">
                <div className="col-md-12 leftbannerdiv">
                    <div>
                        <button className="butonregister" onClick={handleButtonClick}>
                            {isLoggedIn ? t('newCar') : t('mainheadertitle')}
                            <img src="https://i.ibb.co/7RFyXWV/pointer.png" width={35} alt="pointer" />
                        </button>
                        <p>
                            <Trans i18nKey="mainheaderparagraph"></Trans>{' '}
                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                                <Trans i18nKey="mainheaderparagraph24hours"></Trans>
                            </span>{' '}
                            <br />
                            <Trans i18nKey="mainheaderparagraphtherest"></Trans>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import { Box, Divider, TextField, Button, Container, Typography, MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { registerCar, getFutureEvents } from '../services/fetchData';
import Header from '../components/header/Header';
import config from '../config';
import { loadStripe } from '@stripe/stripe-js';
import InputAdornment from '@mui/material/InputAdornment';
import carBrands from './carBrands.json';
import { Trans, useTranslation } from 'react-i18next';
import Spinner from '../components/spinner';
import './Registercar.css'
import Grid from '@mui/material/Grid';


const stripePromise = loadStripe('pk_test_51Kpg5WFdJRiLUWhUcRuEuemQEMZgh7Zoxau8siwAMW69LvbfCvv7XohGuY23Y4msl61oaDVUhytWq8zBbAVLD9ZK00GpoWgirz');


export default function RegisterCar() {
  const [carName, setCarName] = useState('');
  const [carAccident, setCarAccident] = useState('');
  const [cartype, setcartype] = useState('');
  const [minimumPrice, setMinimumPrice] = useState('');
  const [body, setBody] = useState('');
  const [fuel, setFuel] = useState('');
  const [gear, setGear] = useState('');
  const [firstReg, setFirstReg] = useState('');
  const [mileage, setMileage] = useState('');
  const [interior, setInterior] = useState('');
  const [wheelDrive, setWheelDrive] = useState('');
  const [displacement, setDisplacement] = useState('');
  const [exteriorColour, setExteriorColour] = useState('');
  const [numberOfSeats, setNumberOfSeats] = useState('');
  const [transport, setTransport] = useState('');
  const [eventid, seteventid] = useState('');
  const [events, setEvents] = useState([]);
  const [imageInputs, setImageInputs] = useState([{ id: Date.now(), file: null }]);
  const [specialEquipment, setSpecialEquipment] = useState('');
  const [serialEquipment, setSerialEquipment] = useState('');
  const [vehicleInspection, setVehicleInspection] = useState('');
  const [numberOfKeys, setNumberOfKeys] = useState('');
  const [vehicleRegistrationDocument, setvehicleRegistrationDocument] = useState('');
  const [serviceRecordBooklet, setserviceRecordBooklet] = useState('');
  const [mechanics, setmechanics] = useState('');
  const [carfinish, setcarfinish] = useState('');
  const [carmodel, setCarmodel] = useState('');
  const [vehicleidentificationnumber, setvehicleidentificationnumber] = useState('');
  const [carmasternumber, setcarmasternumber] = useState('');
  const [serviceelectrical, setserviceelectrical] = useState('');
  const [generalcondition, setgeneralcondition] = useState('');
  const [repairamount, setrepairamount] = useState('');
  const [expertReport, setexpertReport] = useState('');
  const [registercartype, setregistercartype] = useState('');
  const [interiortype, setinteriortype] = useState('');
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedSection, setSelectedSection] = useState('carData');
  const [carperformance, setCarperformance] = useState('');
  const convertPSToKW = (ps) => {
    return (ps * 0.7355).toFixed(2);
  };
  const [sectionValidation, setSectionValidation] = useState({
    carData: true,
    condition: true,
    documents: true,
    publish: true,
  });

  const validateSections = () => {
    const imagesValid = imageInputs.some((input) => input.files && input.files.length > 0);
    const sectionValidation = {
      carData: !!(
        carName &&
        carmodel &&
        firstReg &&
        mileage &&
        body &&
        cartype &&
        wheelDrive &&
        gear &&
        fuel &&
        displacement &&
        numberOfSeats &&
        exteriorColour &&
        carfinish &&
        interior
      ),
      condition: !!(
        carAccident &&
        vehicleRegistrationDocument &&
        serviceRecordBooklet &&
        serviceelectrical &&
        numberOfKeys &&
        mechanics
      ),
      documents: !!imagesValid,
      publish: !!eventid,
    };
    const missingFields = {
      carData: [],
      condition: [],
      documents: [],
      publish: [],
    };
  
    // Validate Car Data
    if (!carName) missingFields.carData.push('Car Name');
    if (!carmodel) missingFields.carData.push('Car Model');
    if (!firstReg) missingFields.carData.push('1st Registration');
    if (!mileage) missingFields.carData.push('Mileage');
    if (!body) missingFields.carData.push('Body');
    if (!cartype) missingFields.carData.push('Car Type');
    if (!wheelDrive) missingFields.carData.push('Wheel Drive');
    if (!gear) missingFields.carData.push('Gear');
    if (!fuel) missingFields.carData.push('Fuel');
    if (!displacement) missingFields.carData.push('Displacement');
    if (!numberOfSeats) missingFields.carData.push('Number of Seats');
    if (!exteriorColour) missingFields.carData.push('Exterior Colour');
    if (!carfinish) missingFields.carData.push('Car Finish');
    if (!interior) missingFields.carData.push('Interior');
  
    // Validate Condition
    if (!carAccident) missingFields.condition.push('Car Accident');
    if (!vehicleRegistrationDocument) missingFields.condition.push('Vehicle Registration Document');
    if (!serviceRecordBooklet) missingFields.condition.push('Service Record Booklet');
    if (!serviceelectrical) missingFields.condition.push('Service Electrical');
    if (!numberOfKeys) missingFields.condition.push('Number of Keys');
    if (!mechanics) missingFields.condition.push('Mechanics');
  
    // Validate Documents
    if (!imagesValid) missingFields.documents.push('Images');
  
    // Validate Publish
    if (!eventid) missingFields.publish.push('Event ID');
  
    // Check overall validation status
    const isValid = Object.values(missingFields).every((fields) => fields.length === 0);
  
    return { isValid, missingFields, sectionValidation };
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const data = await getFutureEvents();
      setEvents(data.slice(0, 3));
    };

    if (cartype) {
      setCarperformance(convertPSToKW(cartype));
    } else {
      setCarperformance('');
    }

    fetchEvents();
  }, [cartype]);

  const handleFileChange = (index, event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    const maxFileSize = 5 * 1024 * 1024; // 5 MB limit
    
    // Filter out files exceeding the size limit
    const validFiles = files.filter((file) => {
      if (file.size > maxFileSize) {
        alert(`${file.name} exceeds 5 MB and will be skipped.`);
        return false;
      }
      return true;
    });
    
    if (validFiles.length > 0) {
      const newImageInputs = [...imageInputs];
      newImageInputs[index] = {
        ...newImageInputs[index],
        files: validFiles, // Store all valid files as an array
      };
      setImageInputs(newImageInputs);
    }
  };
  
  

  const handlePdfChange = (e) => {
    setexpertReport(e.target.files[0]);
  };

  const handleRemoveImageInput = (index) => {
    const newImageInputs = imageInputs.filter((input, idx) => idx !== index);
    setImageInputs(newImageInputs);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const { isValid, missingFields, sectionValidation } = validateSections();
    setSectionValidation(sectionValidation);
    if (!isValid) {
      const alertMessage = Object.entries(missingFields)
        .filter(([_, fields]) => fields.length > 0)
        .map(
          ([section, fields]) =>
            `In ${section}: ${fields.join(', ')}`
        )
        .join('\n');
  
      alert(`Please fill out all required fields:\n${alertMessage}`);
      setLoading(false);
      return;
    }

    const [year, month] = firstReg.split('-');
    const formattedDate = `${month}/${year}`;

    try {
      const carSpecifications = {
        car_name: carName,
        carmodel: carmodel,
        car_accident: carAccident,
        car_type: cartype,
        Body: body,
        Fuel: fuel,
        Gear: gear,
        "1st reg": formattedDate,
        Mileage: mileage,
        Interior: interior,
        "Wheel drive": wheelDrive,
        Displacement: displacement,
        "Exterior_colour": exteriorColour,
        "Number of seats": numberOfSeats,
        "To be transported by the": transport,
        "vehicleInspection": vehicleInspection,
        "numberOfKeys": numberOfKeys,
        "vehicleRegistrationDocument": vehicleRegistrationDocument,
        "serviceRecordBooklet": serviceRecordBooklet,
        "mechanics": mechanics,
        "carfinish": carfinish,
        "vehicleidentificationnumber": vehicleidentificationnumber,
        "carmasternumber": carmasternumber,
        "serviceelectrical": serviceelectrical,
        "generalcondition": generalcondition,
        "repairamount": repairamount,
        "registercartype": registercartype,
        "interiortype": interiortype,
        "carperformance": carperformance
      };

      const formData = new FormData();
      formData.append('car_specifications', JSON.stringify(carSpecifications));
      formData.append('special_equipment', specialEquipment)
      formData.append('serial_equipment', serialEquipment)
      formData.append('minimum_price', minimumPrice);
      formData.append('expertReport', expertReport);
      formData.append('eventid', eventid);
      imageInputs.forEach((input, index) => {
        if (input.files && input.files.length > 0) {
          input.files.forEach(file => {
            formData.append('images', file); 
          });
        }
      });

      const response = await registerCar(formData);
      const carId = response.car_id;
      const payment = await fetch(`${config.API_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ car_id: carId }),  // Send car_id in the request body
      });
  
      const sessionId = await payment.json();
  
      // Initialize Stripe with the publishable key
      const stripe = await stripePromise;
  
      // Redirect to Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId.sessionId,
      });
      setLoading(false);
    } catch (error) {
      console.error('Failed to register car:', error);
    }
  };

  const getButtonStyle = (section) => ({
    marginTop: '10px',
    color: sectionValidation[section] ? 'inherit' : 'red',
    borderColor: sectionValidation[section] ? 'inherit' : 'red',
  });

  return [
    <Header />,
    <Spinner loading={loading} />,
    <div className='registercardiv'>
            <Box
        sx={{
          width: '250px',
          padding: '20px',
          boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
        }}
        className="sidebarbox"
      >
        <Typography variant="h5" gutterBottom>
          {t('newCar')}
        </Typography>
        <Divider />
        <Button
          fullWidth
          variant={selectedSection === 'carData' ? 'contained' : 'outlined'}
          onClick={() => setSelectedSection('carData')}
          sx={{
            ...getButtonStyle('carData'),
            bgcolor: selectedSection === 'carData' ? 'rgb(239, 239, 227)' : 'inherit',
            color: selectedSection === 'carData' ? 'black' : 'inherit',
            '&:hover': {
              bgcolor: selectedSection === 'carData' ? 'rgb(197 197 187)' : 'inherit',
              color: selectedSection === 'carData' ? 'black' : 'inherit',
            },
          }}
        >
          {t('cardata')}
        </Button>
        <Button
          fullWidth
          variant={selectedSection === 'condition' ? 'contained' : 'outlined'}
          onClick={() => setSelectedSection('condition')}
          sx={{
            ...getButtonStyle('condition'),
            bgcolor: selectedSection === 'condition' ? 'rgb(239, 239, 227)' : 'inherit',
            color: selectedSection === 'condition' ? 'black' : 'inherit',
            '&:hover': {
              bgcolor: selectedSection === 'condition' ? 'rgb(197 197 187)' : 'inherit',
              color: selectedSection === 'condition' ? 'black' : 'inherit',
            },
          }}
        >
          {t('condition')}
        </Button>
        <Button
          fullWidth
          variant={selectedSection === 'documents' ? 'contained' : 'outlined'}
          onClick={() => setSelectedSection('documents')}
          sx={{
            ...getButtonStyle('documents'),
            bgcolor: selectedSection === 'documents' ? 'rgb(239, 239, 227)' : 'inherit',
            color: selectedSection === 'documents' ? 'black' : 'inherit',
            '&:hover': {
              bgcolor: selectedSection === 'documents' ? 'rgb(197 197 187)' : 'inherit',
              color: selectedSection === 'documents' ? 'black' : 'inherit',
            },
          }}
        >
          {t('documentsandphotos')}
        </Button>
        <Button
          fullWidth
          variant={selectedSection === 'publish' ? 'contained' : 'outlined'}
          onClick={() => setSelectedSection('publish')}
          sx={{
            ...getButtonStyle('publish'),
            bgcolor: selectedSection === 'publish' ? 'rgb(239, 239, 227)' : 'inherit',
            color: selectedSection === 'publish' ? 'black' : 'inherit',
            '&:hover': {
              bgcolor: selectedSection === 'publish' ? 'rgb(197 197 187)' : 'inherit',
              color: selectedSection === 'publish' ? 'black' : 'inherit',
            },
          }}
        >
          {t('publish')}
        </Button>
      </Box>
      <Container>
        <form onSubmit={handleSubmit}>
        {selectedSection === 'carData' && (
          <>
          <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal" required>
              <InputLabel><Trans i18nKey="carregistercarbrand"></Trans></InputLabel>
              <Select
                  value={carName}
                  onChange={(e) => setCarName(e.target.value)}
                  label="Car Brand">
                  {carBrands.map((brand, index) => (
                      <MenuItem key={index} value={brand}>
                          {brand}
                      </MenuItem>
                  ))}
              </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
              <TextField
              label={t('carregistermodel')}
              fullWidth
              margin="normal"
              required
              value={carmodel}
              onChange={(e) => setCarmodel(e.target.value)}
            />
          </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                label={t('registercartype')}
                fullWidth
                margin="normal"
                value={registercartype}
                onChange={(e) => setregistercartype(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('carregisterfirstreg')}
                fullWidth
                margin="normal"
                required
                value={firstReg}
                onChange={(e) => setFirstReg(e.target.value)}
                type="month"
                InputLabelProps={{
                  shrink: true,
                }}
              />            
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
              <TextField
              label={t('carregistermileage')}
              fullWidth
              margin="normal"
              required
              value={mileage}
              onChange={(e) => setMileage(e.target.value)}
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
              </Grid>
              <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" required>
              <InputLabel><Trans i18nKey="carregisterbody"></Trans></InputLabel>
              <Select
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  label="Body"
              >
                  <MenuItem value="Limousine"><Trans i18nKey="carregisterbodylimousine"></Trans></MenuItem>
                  <MenuItem value="Estate car"><Trans i18nKey="carregisterbodyestatecar"></Trans></MenuItem>
                  <MenuItem value="Convertible"><Trans i18nKey="carregisterbodyconvertible"></Trans></MenuItem>
                  <MenuItem value="Coupe"><Trans i18nKey="carregisterbodycoupe"></Trans></MenuItem>
                  <MenuItem value="Hatchback"><Trans i18nKey="carregisterbodyhatchback"></Trans></MenuItem>
                  <MenuItem value="SUV/Offroader"><Trans i18nKey="carregisterbodysuvoffroader"></Trans></MenuItem>
                  <MenuItem value="Van"><Trans i18nKey="carregisterbodyvan"></Trans></MenuItem>
                  <MenuItem value="Roadster"><Trans i18nKey="carregisterbodyroadster"></Trans></MenuItem>
                  <MenuItem value="Pickup"><Trans i18nKey="carregisterbodypickup"></Trans></MenuItem>
              </Select>
          </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={3}>
                <TextField
                label={t('carregistertype')}
                fullWidth
                margin="normal"
                required
                value={cartype}
                onChange={(e) => setcartype(e.target.value)}
              />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                label={t('carperformance')}
                fullWidth
                margin="normal"
                value={carperformance}
                InputProps={{
                  readOnly: true,  // Prevent user input
                }}
              />
              </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" required>
              <InputLabel><Trans i18nKey="carregisterwheeldrive"></Trans></InputLabel>
              <Select
                  value={wheelDrive}
                  onChange={(e) => setWheelDrive(e.target.value)}
                  label="Wheel drive"
              >
                  <MenuItem value="Front"><Trans i18nKey="carregisterwheeldrivefront"></Trans></MenuItem>
                  <MenuItem value="Rear"><Trans i18nKey="carregisterwheeldriverear"></Trans></MenuItem>
                  <MenuItem value="4x4"><Trans i18nKey="carregisterwheeldrive4x4"></Trans></MenuItem>
              </Select>
            </FormControl>
            </Grid>
            </Grid>
            

            <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
            <InputLabel><Trans i18nKey="carregistergear"></Trans></InputLabel>
            <Select
                value={gear}
                onChange={(e) => setGear(e.target.value)}
                label="Gear"
            >
                <MenuItem value="Manual"><Trans i18nKey="carregistergearmanual"></Trans></MenuItem>
                <MenuItem value="Automatic"><Trans i18nKey="carregistergearautomatic"></Trans></MenuItem>
                <MenuItem value="Semi automatic"><Trans i18nKey="carregistergearsemiautomatic"></Trans></MenuItem>
            </Select>
          </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel><Trans i18nKey="carregisterfuel"></Trans></InputLabel>
              <Select
                  value={fuel}
                  onChange={(e) => setFuel(e.target.value)}
                  label="Fuel"
              >
                  <MenuItem value="Petrol"><Trans i18nKey="carregisterfuelpetrol"></Trans></MenuItem>
                  <MenuItem value="Diesel"><Trans i18nKey="carregisterfueldiesel"></Trans></MenuItem>
                  <MenuItem value="Electrical"><Trans i18nKey="carregisterfuelelectrical"></Trans></MenuItem>
                  <MenuItem value="Gas (CNG)"><Trans i18nKey="carregisterfuelgascng"></Trans></MenuItem>
                  <MenuItem value="Hybrid"><Trans i18nKey="carregisterfuelhybrid"></Trans></MenuItem>
                  <MenuItem value="Gas (LPG)"><Trans i18nKey="carregisterfuelgaslpg"></Trans></MenuItem>
                  <MenuItem value="Hybrid/petrol"><Trans i18nKey="carregisterfuelhybridpetrol"></Trans></MenuItem>
                  <MenuItem value="Hybrid/diesel"><Trans i18nKey="carregisterfuelhybriddiesel"></Trans></MenuItem>
                  <MenuItem value="Hydrogen"><Trans i18nKey="carregisterfuelhydrogen"></Trans></MenuItem>
              </Select>
          </FormControl>
            </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
            <TextField
          label={t('carregisterdisplacement')}
          fullWidth
          margin="normal"
          required
          value={displacement}
          onChange={(e) => setDisplacement(e.target.value)}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">liters</InputAdornment>,
            inputProps: { min: 0 }, // Optional: Prevent negative numbers
          }}
        />
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
          <InputLabel><Trans i18nKey="carregisternumberofseats"></Trans></InputLabel>
          <Select
              value={numberOfSeats}
              onChange={(e) => setNumberOfSeats(e.target.value)}
              label="Number of seats"
          >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
          </Select>
      </FormControl>
            </Grid>

            </Grid>


            <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
            <InputLabel><Trans i18nKey="carregisterexteriorcolour"></Trans></InputLabel>
            <Select
                value={exteriorColour}
                onChange={(e) => setExteriorColour(e.target.value)}
                label="Exterior colour"
            >
                <MenuItem value="Black"><Trans i18nKey="carregisterexteriorcolourblack"></Trans></MenuItem>
                <MenuItem value="White"><Trans i18nKey="carregisterexteriorcolourwhite"></Trans></MenuItem>
                <MenuItem value="Red"><Trans i18nKey="carregisterexteriorcolourred"></Trans></MenuItem>
                <MenuItem value="Blue"><Trans i18nKey="carregisterexteriorcolourblue"></Trans></MenuItem>
                <MenuItem value="Green"><Trans i18nKey="carregisterexteriorcolourgreen"></Trans></MenuItem>
                <MenuItem value="Silver"><Trans i18nKey="carregisterexteriorcoloursilver"></Trans></MenuItem>
                <MenuItem value="Gray"><Trans i18nKey="carregisterexteriorcolourgray"></Trans></MenuItem>
                <MenuItem value="Yellow"><Trans i18nKey="carregisterexteriorcolouryellow"></Trans></MenuItem>
                <MenuItem value="Orange"><Trans i18nKey="carregisterexteriorcolourorange"></Trans></MenuItem>
                <MenuItem value="Brown"><Trans i18nKey="carregisterexteriorcolourbrown"></Trans></MenuItem>
            </Select>
        </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel><Trans i18nKey="carregistercarfinish"></Trans></InputLabel>
              <Select
                  value={carfinish}
                  onChange={(e) => setcarfinish(e.target.value)}
                  label="Car finish"
              >
                  <MenuItem value="Bubble effect"><Trans i18nKey="carregistercarfinisbubbleeffect"></Trans></MenuItem>
                  <MenuItem value="Individual paint"><Trans i18nKey="carregistercarfinishindividualpaint"></Trans></MenuItem>
                  <MenuItem value="Matt paint"><Trans i18nKey="carregistercarfinishmattpaint"></Trans></MenuItem>
                  <MenuItem value="Metallic"><Trans i18nKey="carregistercarfinishmetallic"></Trans></MenuItem>
                  <MenuItem value="Solid paint"><Trans i18nKey="carregistercarfinishsolidpaint"></Trans></MenuItem>
              </Select>
          </FormControl>
            </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel><Trans i18nKey="carregisterinterior"></Trans></InputLabel>
              <Select
                  value={interior}
                  onChange={(e) => setInterior(e.target.value)}
                  label="Interior"
              >
                  <MenuItem value="Black"><Trans i18nKey="carregisterinteriorblack"></Trans></MenuItem>
                  <MenuItem value="Beige"><Trans i18nKey="carregisterinteriorbeige"></Trans></MenuItem>
                  <MenuItem value="Gray"><Trans i18nKey="carregisterinteriorgray"></Trans></MenuItem>
                  <MenuItem value="Brown"><Trans i18nKey="carregisterinteriorbrown"></Trans></MenuItem>
                  <MenuItem value="Red"><Trans i18nKey="carregisterinteriorred"></Trans></MenuItem>
                  <MenuItem value="Blue"><Trans i18nKey="carregisterinteriorblue"></Trans></MenuItem>
                  <MenuItem value="White"><Trans i18nKey="carregisterinteriorwhite"></Trans></MenuItem>
                  <MenuItem value="Green"><Trans i18nKey="carregisterinteriorgreen"></Trans></MenuItem>
                  <MenuItem value="Tan"><Trans i18nKey="carregisterinteriortan"></Trans></MenuItem>
                  <MenuItem value="Cream"><Trans i18nKey="carregisterinteriorcream"></Trans></MenuItem>
              </Select>
          </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel><Trans i18nKey="interiortype"></Trans></InputLabel>
              <Select
                  value={interiortype}
                  onChange={(e) => setinteriortype(e.target.value)}
                  label="Interior Type"
              >
                  <MenuItem value="Alcantara"><Trans i18nKey="interiortypealcantara"></Trans></MenuItem>
                  <MenuItem value="Leather"><Trans i18nKey="interiortypealeather"></Trans></MenuItem>
              </Select>
          </FormControl>
            </Grid>
            </Grid>
          </>
          
        )}
        {selectedSection === 'condition' && (
          <>
            <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel><Trans i18nKey="carregistercaraccident"></Trans></InputLabel>
              <Select
                  value={carAccident}
                  onChange={(e) => setCarAccident(e.target.value)}
                  label={t('carregisteraccident')}
              >
                  <MenuItem value="yes"><Trans i18nKey="carregistercaraccidentyes"></Trans></MenuItem>
                  <MenuItem value="no"><Trans i18nKey="carregistercaraccidentno"></Trans></MenuItem>
              </Select>
          </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <TextField
            label={t('carregistervehicleinspection')}
            fullWidth
            margin="normal"
            required
            value={vehicleInspection}
            onChange={(e) => setVehicleInspection(e.target.value)}
          />
            </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
            <InputLabel><Trans i18nKey="carregistervehicleregistrationdocument"></Trans></InputLabel>
            <Select
                value={vehicleRegistrationDocument}
                onChange={(e) => setvehicleRegistrationDocument(e.target.value)}
                label="Vehicle registration document"
            >
                <MenuItem value="Available"><Trans i18nKey="carregistercarregistervehicleavailable"></Trans></MenuItem>
                <MenuItem value="Will provide later"><Trans i18nKey="carregistercarregistervehiclewillprovidelater"></Trans></MenuItem>
            </Select>
        </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
            <InputLabel><Trans i18nKey="carregisterservicerecordbooklet"></Trans></InputLabel>
            <Select
                value={serviceRecordBooklet}
                onChange={(e) => setserviceRecordBooklet(e.target.value)}
                label="Service record booklet"
            >
                <MenuItem value="Available"><Trans i18nKey="carregisterservicerecordbookletavailable"></Trans></MenuItem>
                <MenuItem value="Incomplete"><Trans i18nKey="carregisterservicerecordbookletincomplete"></Trans></MenuItem>
                <MenuItem value="Missing"><Trans i18nKey="carregisterservicerecordbookletmissing"></Trans></MenuItem>
            </Select>
        </FormControl>
            </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
            <InputLabel><Trans i18nKey="carregisterserviceelectrical"></Trans></InputLabel>
            <Select
                value={serviceelectrical}
                onChange={(e) => setserviceelectrical(e.target.value)}
                label="Service Electrical"
            >
                <MenuItem value="Available"><Trans i18nKey="carregisterserviceelectricalavailable"></Trans></MenuItem>
                <MenuItem value="Incomplete"><Trans i18nKey="carregisterserviceelectricalincomplete"></Trans></MenuItem>
                <MenuItem value="Missing"><Trans i18nKey="carregisterservicerecordelectricalmissing"></Trans></MenuItem>
            </Select>
        </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" required>
          <InputLabel><Trans i18nKey="carregisternumberofkeys"></Trans></InputLabel>
          <Select
              value={numberOfKeys}
              onChange={(e) => setNumberOfKeys(e.target.value)}
              label="Number of keys"
          >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
          </Select>
      </FormControl>
            </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
            <TextField
            label={t('carregistermechanics')}
            fullWidth
            margin="normal"
            required
            value={mechanics}
            onChange={(e) => setmechanics(e.target.value)}
          />
            </Grid>
            <Grid item xs={12} md={6}>
            <TextField
            label={t('carregisterrepairsamount')}
            fullWidth
            margin="normal"
            type="number"
            required
            value={repairamount}
            onChange={(e) => setrepairamount(e.target.value)}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
            </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
            <TextField
            label={t('carregistervehicleidentificationnumber')}
            fullWidth
            margin="normal"
            value={vehicleidentificationnumber}
            onChange={(e) => setvehicleidentificationnumber(e.target.value)}
          />
            </Grid>
            <Grid item xs={12} md={6}>
            <TextField
            label={t('carregistermasternumber')}
            fullWidth
            margin="normal"
            value={carmasternumber}
            onChange={(e) => setcarmasternumber(e.target.value)}
          />
            </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal">
            <InputLabel><Trans i18nKey="carregistergeneralcondition"></Trans></InputLabel>
            <Select
                value={generalcondition}
                onChange={(e) => setgeneralcondition(e.target.value)}
                label="General Condition"
            >
                <MenuItem value="Hail Damage"><Trans i18nKey="carregistergeneralconditionhaildamage"></Trans></MenuItem>
                <MenuItem value="Dog Owner"><Trans i18nKey="carregistergeneralconditiondogowner"></Trans></MenuItem>
                <MenuItem value="Smoker Vehicle"><Trans i18nKey="carregistergeneralconditionsmokervehicle"></Trans></MenuItem>
            </Select>
        </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>

            </Grid>
            </Grid>

          </>

        )}

        {selectedSection === 'documents' && (
         <>
                   <div style={{marginBottom: 30}}>
            <InputLabel>Expert Report (Pdf)</InputLabel>
            <input 
              type="file" 
              accept=".pdf" 
              onChange={handlePdfChange} 
              name="expertReport" 
            />
          </div>
          {imageInputs.map((input, index) => (
            <div key={input.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <label>Upload Image</label>
                <input
                  type="file"
                  multiple
                  onChange={(event) => handleFileChange(index, event)}
                  required
                />
              </div>
              {imageInputs.length > 1 && (
                <IconButton onClick={() => handleRemoveImageInput(index)}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              )}
            </div>
          ))}
         </>
        )}

        {selectedSection === 'publish' && (
          <>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
              <TextField
            label={t('carregisterminimumprice')}
            fullWidth
            margin="normal"
            type="number"
            required
            value={minimumPrice}
            onChange={(e) => setMinimumPrice(e.target.value)}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
              </Grid>
              <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" required>
          <InputLabel><Trans i18nKey="carregistertobetransported"></Trans></InputLabel>
          <Select
              value={transport}
              onChange={(e) => setTransport(e.target.value)}
              label="To be transported by the"
          >
            <MenuItem value="AG">AG (Aargau)</MenuItem>
            <MenuItem value="AI">AI (Appenzell Innerrhoden)</MenuItem>
            <MenuItem value="AR">AR (Appenzell Ausserrhoden)</MenuItem>
            <MenuItem value="BE">BE (Bern)</MenuItem>
            <MenuItem value="BL">BL (Basel-Landschaft)</MenuItem>
            <MenuItem value="BS">BS (Basel-Stadt)</MenuItem>
            <MenuItem value="FR">FR (Fribourg)</MenuItem>
            <MenuItem value="GE">GE (Geneva)</MenuItem>
            <MenuItem value="GL">GL (Glarus)</MenuItem>
            <MenuItem value="GR">GR (Graubünden)</MenuItem>
            <MenuItem value="JU">JU (Jura)</MenuItem>
            <MenuItem value="LU">LU (Lucerne)</MenuItem>
            <MenuItem value="NE">NE (Neuchâtel)</MenuItem>
            <MenuItem value="NW">NW (Nidwalden)</MenuItem>
            <MenuItem value="OW">OW (Obwalden)</MenuItem>
            <MenuItem value="SG">SG (St. Gallen)</MenuItem>
            <MenuItem value="SH">SH (Schaffhausen)</MenuItem>
            <MenuItem value="SO">SO (Solothurn)</MenuItem>
            <MenuItem value="SZ">SZ (Schwyz)</MenuItem>
            <MenuItem value="TG">TG (Thurgau)</MenuItem>
            <MenuItem value="TI">TI (Ticino)</MenuItem>
            <MenuItem value="UR">UR (Uri)</MenuItem>
            <MenuItem value="VD">VD (Vaud)</MenuItem>
            <MenuItem value="VS">VS (Valais)</MenuItem>
            <MenuItem value="ZG">ZG (Zug)</MenuItem>
            <MenuItem value="ZH">ZH (Zurich)</MenuItem>
          </Select>
      </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal">
            <InputLabel id="eventid-label">Event *</InputLabel>
            <Select
              labelId="eventid-label"
              id="eventid"
              value={eventid}
              onChange={(e) => seteventid(e.target.value)}
              label="Event ID"
              required
            >
              {events.map((event) => (
                <MenuItem key={event.id} value={event.id}>
                  {`${event.id} - ${new Date(event.startdate).toLocaleDateString()}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            </Grid>
            </Grid>

          <Button type="submit" variant="contained" style={{background: 'rgb(239, 239, 227)', color: 'black'}}>
            Register
          </Button>
          </>

        )}
        
          {/* <TextField
            label={t('carregisterspecialequipment')}
            fullWidth
            margin="normal"
            required
            value={specialEquipment}
            onChange={(e) => setSpecialEquipment(e.target.value)}
          />
          <TextField
            label={t('carregisterserialequipment')}
            fullWidth
            margin="normal"
            required
            value={serialEquipment}
            onChange={(e) => setSerialEquipment(e.target.value)}
          /> */}
        </form>
      </Container>

    </div>,
    
  ];
}

import Header from "../components/header/Header";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../components/sidebar/sidebar";
import Instructions from "../components/sidebar/instructions";
import Prices from "../components/sidebar/prices";
import GeneralCondition from "../components/sidebar/gc";
import Dpr from "../components/sidebar/dpr";
import './Howitworks.css';

const Howitworks = () => {
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState('instructions');

  // Function to get query parameters from URL
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  // Update selectedPage when URL changes
  useEffect(() => {
    const page = getQueryParam('page');
    if (page) {
      setSelectedPage(page);
    }
  }, [location]);

  const renderContent = () => {
    switch (selectedPage) {
      case 'instructions':
        return <Instructions />;
      case 'prices':
        return <Prices />;
      case 'gc':
        return <GeneralCondition />;
      case 'dpr':
        return <Dpr />;
      default:
        return <Instructions />;
    }
  };

  return (
    <>    
      <Header/>
      <div className="howitworksdiv">
        <div className="leftside">
          <Sidebar onSelectPage={setSelectedPage} />
        </div>
        <div className="rightside">
          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default Howitworks;

import React, { useState, useEffect } from 'react';
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';
import axios from 'axios';
import config from '../../config';

export default function CarImageGallery({ carId }) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${config.API_URL}/carimages/${carId}`);
        const imageUrls = response.data.car_images.map(imagePath => ({
          original: `${config.API_URL}${imagePath}`,
          thumbnail: `${config.API_URL}${imagePath}`,
        }));
        setImages(imageUrls);
      } catch (error) {
        console.error('Failed to fetch car images:', error);
      }
    };

    fetchImages();
  }, [carId]);

  return (
    <ImageGallery items={images} />
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinnerloader {
    margin: auto;
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    background: #2a2828;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Spinner.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,uBAAuB;AAC3B","sourcesContent":[".spinnerloader {\n    margin: auto;\n    width: fit-content;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    background: #2a2828;\n    width: 100%;\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 99;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

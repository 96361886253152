import React, { useState, useEffect } from 'react';
import Header from "../components/header/Header";
import config from '../config';
import './Admin.css'; // Add CSS file for styling

export default function Admin() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetch(`${config.API_URL}/getpendingusers`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => response.json())
        .then(data => {
            // Parse the details field in each user object
            const parsedData = data.map(user => ({
                ...user,
                details: JSON.parse(user.details)
            }));
            setUsers(parsedData);
        })
        .catch(error => console.error('Error fetching users:', error));
    }, []);

    const activateUser = (id) => {
        fetch(`${config.API_URL}/activateuser/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // Reload the page after successful activation
                window.location.reload();
            }
        })
        .catch(error => console.error('Error activating user:', error));
    };

    const renderDetails = (details) => {
        return (
            <table className="details-table">
                <tbody>
                    {Object.entries(details).map(([key, value]) => (
                        <tr key={key}>
                            <td><strong>{key}:</strong></td>
                            <td>{value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <>
            <Header />
            <div className='adminusertable'>
                <h1>Pending Users</h1>
                <table className="user-table">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Details</th>
                            {/* <th>Active</th> */}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.username}>
                                <td>{user.username}</td>
                                <td>{renderDetails(user.details)}</td>
                                {/* <td>{user.active}</td> */}
                                <td>
                                    {user.active === 0 && (
                                        <button style={{background: '#1D1E42', fontFamily: 'Poppins', fontWeight: 'bold', color: 'white', borderRadius: 30}} onClick={() => activateUser(user.id)}>
                                            Activate
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

import PuffLoader from "react-spinners/ClipLoader";
import {useState} from 'react';
import './Spinner.css';

export default function Spinner({loading}) {
    let [color] = useState("#FFFF00");
    
    return (
        loading && (
            <div className="spinnerloader">
                <PuffLoader
                    color={color}
                    loading={loading}
                    size={200}
                />
            </div>
        )
    );
}

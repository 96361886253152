import Header from "../components/header/Header";
import { useState } from "react";
import Sidebar from "../components/sidebar/sidebar";
import Instructions from "../components/sidebar/instructions";
import Prices from "../components/sidebar/prices";
import GeneralCondition from "../components/sidebar/gc";
import Dpr from "../components/sidebar/dpr";
import './Howitworks.css';

const Howitworks = () => {
  const [selectedPage, setSelectedPage] = useState('home');

  const renderContent = () => {
    switch (selectedPage) {
      case 'instruction':
        return <Instructions />;
      case 'prices':
        return <Prices />;
      case 'gc':
        return <GeneralCondition />;
      case 'dpr':
        return <Dpr />;
      default:
        return <Instructions />;
    }
  };

  return (
    <>    
    <Header/>
        <div className="howitworksdiv">
          <div className="leftside">
              <Sidebar onSelectPage={setSelectedPage} />
          </div>
          <div className="rightside">
              {renderContent()}
          </div>
        </div>
    </>
  );
};

export default Howitworks;

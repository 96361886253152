import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useTranslation } from 'react-i18next';


export default function Instructions() {
    const {t} = useTranslation();

    const styles = {
      twoColumnList: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px',
        maxWidth: '800px',
      },
      row: {
        display: 'contents', // Ensures grid rows are auto-handled
      },
      leftColumn: {
        textAlign: 'left',
        paddingRight: '10px',
        fontWeight: 'bold',
      },
      rightColumn: {
        textAlign: 'left',
      },
    };
  
    return (
      <>
        <div style={{fontFamily: 'Poppins'}}>
          <div>
            <p><strong>{t('general_and_instructions')}</strong></p>
            
            <p>{t('welcome_message')}</p>
            <p>{t('description')}</p>
            
            <p>{t('auction_schedule')}</p>
            <ul>
              {t('auction_times', { returnObjects: true }).map((time, index) => (
                <li key={index}>{time}</li>
              ))}
            </ul>
            
            <p>{t('payment_description')}</p>
            <p>{t('bidding_info')}</p>
            <p>{t('auction_end_message')}</p>
            <p>{t('bidder_condition')}</p>
            <p>{t('disclaimer')}</p>
          </div>
          </div>
      </>
    );
  }
  
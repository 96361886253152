import './Prices.css';
import { useTranslation } from 'react-i18next';


export default function Prices() {
    const {t} = useTranslation();
    return(
        <>
            <div>
                <p><strong>{t('pricespervehicle')}</strong></p>
                
                <p><strong>{t('pricesseller')}</strong></p>

                <table className="table">
                    <thead>
                        <tr>
                            <th>{t('priceproduct')}</th>
                            <th>{t('priceprice')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t('priceonetime')}</td>
                            <td>49 CHF</td>
                        </tr>
                    </tbody>
                </table>

                <p><strong>{t('pricebuyer')}</strong></p>

                <table className="table">
                    <thead>
                        <tr>
                            <th>{t('priceproduct')}</th>
                            <th>{t('priceprice')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t('priceonetimebuyer')}</td>
                            <td>49 CHF</td>
                        </tr>
                    </tbody>
                </table>

                <p>{t('pricedescription')}</p>


                <p>{t('priceincludevat')}</p>
            </div>
        </>
    )
}

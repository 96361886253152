// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editcar-container {
    margin: auto;
    width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/pages/EditCar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;AACd","sourcesContent":[".editcar-container {\n    margin: auto;\n    width: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registercardiv {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;

}

@media (max-width: 480px) {
    .registercardiv {
        flex-direction: column;
    }

    .sidebarbox {
        width: auto !important;
        margin-top: 10vh;
        box-shadow: none !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Registercar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,YAAY;;AAEhB;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,sBAAsB;QACtB,gBAAgB;QAChB,2BAA2B;IAC/B;AACJ","sourcesContent":[".registercardiv {\n    display: flex;\n    flex-direction: row;\n    width: 90%;\n    margin: auto;\n\n}\n\n@media (max-width: 480px) {\n    .registercardiv {\n        flex-direction: column;\n    }\n\n    .sidebarbox {\n        width: auto !important;\n        margin-top: 10vh;\n        box-shadow: none !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

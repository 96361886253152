import config from "../config";

export const fetchCarById = async (carId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${config.API_URL}/getcarbyid/${carId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch car details');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching car details:', error);
    throw error;
  }
};

export const fetchData = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${config.API_URL}/getnextevent`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};

export const fetchCarData = async (id) => {
  try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${config.API_URL}/getcarinfo/${id}`, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });
      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error fetching data:', error);
      return null;
  }
};

export async function updateCurrentBidPrice(carId, newPrice) {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${config.API_URL}/updatecurrentbidprice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                carid: carId,
                newprice: newPrice
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Failed to fetch:', error);
        throw error;
    }
}

export const registerCar = async (formData) => {
  const response = await fetch(`${config.API_URL}/registercar`, {
    method: 'POST',
    body: formData, // Send formData directly
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });

  if (!response.ok) {
    throw new Error('Failed to register car');
  }

  return response.json();
};

  export const fetchMyCars = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${config.API_URL}/mycars`, { 
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      return await response.json();
    } catch (error) {
      localStorage.clear('token');
      console.error('Failed to fetch cars:', error);
      throw error;
    }
  };

  export const getFutureEvents = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${config.API_URL}/getfutureevents`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Failed to fetch future events:', error);
      return [];
    }
  };

  export const fetchEvents = async (token) => {
    try {
      const response = await fetch(`${config.API_URL}/getevent`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`, // Include JWT token if required
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch events');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error:', error);
      throw error; // Re-throw error to handle it where fetchEvents is called
    }
  };
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Car from "./pages/car";
import PageTitle from "./components/header/pagetitle";
import Login from "./pages/login";
import Register from "./pages/register";
import SignOut from "./pages/signout";
import PrivateRoute from "./components/PrivateRoute";
import Mycars from "./pages/mycars";
import RegisterCar from "./pages/registercar";
import Events from "./pages/events";
import AddEvent from "./components/addevent";
import Footer from "./components/footer";
import MyCar from "./pages/mycar";
import Howitworks from "./pages/howitworks";
import ProductPayment from "./pages/checkoutbutton";
import Admin from "./pages/admin";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/400-italic.css";
import ResetPassword from "./pages/resetpassword";
import ForgetPassword from "./pages/ForgetPassword";
import EditCar from "./pages/editcar";
import MyAccount from "./pages/myaccount";

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/signout" element={<SignOut />} />
          <Route
            index
            element={
                <>
                  <PageTitle title="Home Page" />
                  <Home />
                </>
            }
          />
          {/* <Route
            index
            element={
              <PrivateRoute>
                <>
                  <PageTitle title="Home Page" />
                  <Home />
                </>
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/car/:id"
            element={
                <>
                  <PageTitle title="Car Page" />
                  <Car />
                </>
            }
          />
          <Route
            path="/editcar/:id"
            element={
                <>
                  <PageTitle title="Edit Car Page" />
                  <EditCar />
                </>
            }
          />
          <Route
            path="/mycars"
            element={
              <PrivateRoute>
                <>
                  <PageTitle title="My cars" />
                  <Mycars />
                </>
              </PrivateRoute>
            }
          />
          <Route
            path="/registercar"
            element={
              <PrivateRoute>
                <>
                  <PageTitle title="Register car" />
                  <RegisterCar />
                </>
              </PrivateRoute>
            }
          />
          <Route
            path="/events"
            element={
              <PrivateRoute>
                <>
                  <PageTitle title="Events" />
                  <Events />
                </>
              </PrivateRoute>
            }
          />
          <Route
            path="/addevent"
            element={
              <PrivateRoute>
                <>
                  <PageTitle title="AddEvent" />
                  <AddEvent />
                </>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <>
                  <PageTitle title="Admin" />
                  <Admin />
                </>
              </PrivateRoute>
            }
          />
          <Route
            path="/myaccount"
            element={
              <PrivateRoute>
                <>
                  <PageTitle title="My Account" />
                  <MyAccount />
                </>
              </PrivateRoute>
            }
          />
          <Route
            path="/mycar/:id"
            element={
                <>
                  <PageTitle title="My Car Page" />
                  <MyCar />
                </>
            }
          />
          <Route
            path="/how-it-works"
            element={
                <>
                  <PageTitle title="How it works" />
                  <Howitworks />
                </>
            }
          />
          <Route
            path="/forgotpassword"
            element={
                <>
                  <PageTitle title="Forgot password" />
                  <ForgetPassword />
                </>
            }
          />
          <Route
            path="/reset_password/:token"
            element={
                <>
                  <PageTitle title="Reset password" />
                  <ResetPassword />
                </>
            }
          />
          <Route
            path="/paymentpage"
            element={
                <>
                  <PageTitle title="Payment Page" />
                  <ProductPayment />
                </>
            }
          />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

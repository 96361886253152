import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function Instructions() {
    const styles = {
      twoColumnList: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px',
        maxWidth: '800px',
      },
      row: {
        display: 'contents', // Ensures grid rows are auto-handled
      },
      leftColumn: {
        textAlign: 'left',
        paddingRight: '10px',
        fontWeight: 'bold',
      },
      rightColumn: {
        textAlign: 'left',
      },
    };
  
    return (
      <>
        <div style={{fontFamily: 'Poppins'}}>
          <h2>How it works</h2>
          <p>
            Auction - generel informations AutoAuction24 is an e-auction platform developed for the needs of sellers and
            buyers in the B2B sector. Like with conventional auctions, a buyer can bid for several vechicles but can be
            outbid when third parties bid higher. This applies to the entire auction time. Our auctions have fix lengths
            of time.
          </p>
          <p>Target audience</p>
          <div style={styles.twoColumnList}>
            <div style={styles.row}>
              <div style={styles.leftColumn}>Publishers</div>
              <div style={styles.rightColumn}>Buyer</div>
            </div>
            <div style={styles.row}>
              <div style={styles.leftColumn}>Leasing companies</div>
              <div style={styles.rightColumn}>Brand representatives</div>
            </div>
            <div style={styles.row}>
              <div style={styles.leftColumn}>Fleet owners</div>
              <div style={styles.rightColumn}>Independent merchants</div>
            </div>
            <div style={styles.row}>
              <div style={styles.leftColumn}>Car rentals</div>
              <div style={styles.rightColumn}>Body shops</div>
            </div>
            <div style={styles.row}>
              <div style={styles.leftColumn}>Importers</div>
              <div style={styles.rightColumn}>Paint shops</div>
            </div>
          </div>
        </div>
        <p>Important: The vehicles and their detailed view are visible for everyone. The minimum prices and the highest bids are only visible after successful registration. This requires, however, that the user has previously completed a one-time, free registration.</p>
        <div className='Accordion' style={{width: '100%'}}>
            <Accordion>
            <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            <Typography>Registration</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            AutoAuction24 AG is a B2B e-auction plattform in the automotive sector, therefore for companies in the car industry only. <br></br>

In case you are a private person interested in a car in the auction, we kindly ask you to approach your garage to let them bid for you. <br></br>

In case you are a private person interested in a car in the auction, we kindly ask you to approach your garage to let them bid for you.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            <Typography>Selling</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            To optimize sales, AutoAuction24 recommends to provide a neutral expertise. Feel free to get in touch for further informations, Team AutoAuction24 will be happy to assist you. <br>
            </br>
            <br></br>
            Selling your cars made easy:
            <br>
            </br>
            <br></br>
            a) Import details by using the type no. and 1st license code (Swiss cars).
            <li>Complete the data of your cars (mileage, equipment features, etc.)</li>
            <li>Provide informations about the car's condition.</li>
            <li>Upload photos and documents.</li>
            <li>Set minimum price and handover options</li>
            <br></br>
            b) Add your car manually
            <p>After these steps, you car can be added to an auction.</p>
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            <Typography>Auction times</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            AutoAuction24 leads two auctions per week:<br>
            </br>
            <br></br>
            1st auction: Monday from 12pm to Tuesday 10am (overtime excl.)
            2nd auction: Wednesday from 12pm to Thursday 10am (overtime excl.)
            <br>
            </br>
            <br></br>
            Bids placed after 9.50am will extend the auction of the respective time for 5 minutes.
            Bids under reserve (below the minumum price) may be accepted or rejected until 4pm after the auction's end. The vehicles of the current auction remain visible on our front page until 4pm (on the day the action ends).
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            <Typography>Buyer's premiums</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            When clicking on a car, its photo or on the buy-now button, the detailed view page of the respective vehicle will open. You'll have access to the detailed configuration, repair costs and the current prices.<br>
            </br>
            <br></br>
            You can select a bid via the "BidMeter" and confirm it by clicking on the "Bid now" button.
            The minimum markup is usually as follows:
            <br>
            </br>
            <li>up to CHF 10'000.-	CHF 100.-</li>
            <li>CHF 10'001.- to CHF 25'000.-	CHF 150.-</li>
            <li>CHF 25'001.- to CHF 45'000.-	CHF 200.-</li>
            <li>CHF 45'001.- to CHF 70'000.-	CHF 250.-</li>
            <li>from CHF 70'000.-	CHF 500.-</li>
            For a better overview, all costs such as the Bbyer's premium, transport costs etc are displayed.
            <br></br>
            Bids submitted after 09:55 will automatically extend the auction of the respective vehicle by 5 minutes.
            Under reserve
            <br></br>
            If you are the highest bidder but your bid is below the seller's minimum price and you haven't received a purchase confirmation within our auctioning times , it means the seller did not confirm your bid. These kind of bids are under reserve and binding for the buyer only.
            <br>
            </br>
            The current status after the auction is shown as follows:
            <br></br>
            <li>Your bid has been accepted.</li>
            <li>You've been outbidden or your bid was rejected.</li>
            <li>Decision still pending (until 4pm on the day the auction ends)</li>
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            <Typography>After the auction</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            Once the auction has ended, an email will be sent to the seller and the winning buyer. Once the sale has been confirmed, we will share the buyer's and seller's details to each other. If not otherwise agreed, the vehicles will be released after successful payment. AutoAuction24 will invoice the buyer for the costs of the vechicle with the corresponding fees.
            <br></br>
            <br></br>
            Under reserve
            <br>
            </br>
            <br></br>
            If you are the highest bidder but your bid is below the seller's minimum price and you haven't received a purchase confirmation within our auctioning times, it means the seller did not confirm your bid. These kind of bids are under reserve and binding for the buyer only.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            <Typography>Service times</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            Our offices operate as follows:   <br></br>
            <br></br>
            Mo-Fr 8am-12pm / 1.30pm-5pm
            <br>
            </br>
            <br></br>
            info@AutoAuction24.ch
            <br></br>
            phone 055 533 40 00
            </Typography>
            </AccordionDetails>
        </Accordion>
        </div>
      </>
    );
  }
  
import React, { useState, useEffect } from 'react';
import { Slider, Box, Grid, Typography, Autocomplete, FormControlLabel, Checkbox } from '@mui/material';
import { json, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { fetchData } from '../../services/fetchData';
import CountDown from './countdown';
import './BasicTable.css';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import Spinner from '../spinner';
import { Button } from '@mui/material';
import io from 'socket.io-client';

const socket = io('https://autoauction24.ch', {
  path: '/api/socket.io'
});

export default function BasicTable() {
  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');
  const [favouriteCarIds, setFavouriteCarIds] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterMileageMin, setFilterMileageMin] = useState('');
  const [filterMileageMax, setFilterMileageMax] = useState('');
  const [filterYearMin, setFilterYearMin] = useState('');
  const [filterYearMax, setFilterYearMax] = useState('');
  const [filterPrice, setFilterPrice] = useState('');
  const [yearRange, setYearRange] = useState([1900, 2025]);
  const [mileageRange, setMileageRange] = useState([0, 300000]);
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [showMileageFilter, setShowMileageFilter] = useState(false);
  const [showYearFilter, setShowYearFilter] = useState(false);
  const [showPriceFilter, setShowPriceFilter] = useState(false);
  const [showBrandFilter, setShowBrandFilter] = useState(null);
  const [showGearFilter, setShowGearFilter] = useState(null);
  const [showBodyFilter, setShowBodyFilter] = useState(null);
  const [showFuelFilter, setShowFuelFilter] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedBodyTypes, setSelectedBodyTypes] = useState([]);
  const [selectedGearTypes, setSelectedGearTypes] = useState([]);
  const [selectedFuelTypes, setSelectedFuelTypes] = useState([]);

  const getUniqueValues = (rows, key) => {
    const values = new Set();
    rows.forEach((row) => {
      if (row.specifications?.[key]) {
        values.add(row.specifications[key]);
      }
    });
    return Array.from(values).sort();
  };

  const bodyTypes = getUniqueValues(rows, 'Body');
  const gearTypes = getUniqueValues(rows, 'Gear');
  const fuelTypes = getUniqueValues(rows, 'Fuel');

  const getUniqueCarBrands = (rows) => {
    const brands = new Set();
    rows.forEach((row) => {
      if (row.specifications?.car_name) {
        brands.add(row.specifications.car_name);
      }
    });
    return Array.from(brands).sort();
  };

  const carBrands = getUniqueCarBrands(rows);

  useEffect(() => {
    setIsLoggedIn(!!token);
    // console.log(user)

    const getData = async () => {
      setLoading(true);
      const data = await fetchData();

      if (data && data.length === undefined) {
        setStartDate(data.startdate);
        setEndDate(data.enddate);
        setRows(data.cars || []);
      } else if (data && data.length > 0) {
        setStartDate(data[0].startdate);
        setEndDate(data[0].enddate);
        setRows(data[0].cars || []);
      }

      // Call showFavourites only if the user is logged in
      if (isLoggedIn) {
        await showFavourites();
      }
      setLoading(false);
    };

    getData();

    socket.connect();

    socket.on('connect', () => {
      console.log('Connected to Socket.IO server');
    });

    socket.on('bid_update', getData);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      // Hide mobile filters when switching to desktop view
      if (window.innerWidth > 768) {
        setShowMobileFilters(false);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      socket.off('bid_update', getData);
      socket.disconnect();
    };
  }, [isLoggedIn]);

  const handleRowClick = (id) => {
    navigate(`/car/${id}`);
  };

  const filteredRows = rows.filter((row) => {
    const carName = row.specifications?.car_name || '';
    const mileage = row.specifications?.Mileage || '';
    const carminimumprice = row.minimumprice || '';
    const regDate = row.specifications?.['1st reg'] || '';
    const bodyType = row.specifications?.Body || '';
    const gearType = row.specifications?.Gear || '';
    const fuelType = row.specifications?.Fuel || '';

    const regYear = regDate.split('/')[1];

    const matchesName = carName.toLowerCase().includes(filterText.toLowerCase());
    const matchesMileage =
      (filterMileageMin === '' || Number(mileage) >= filterMileageMin) &&
      (filterMileageMax === '' || Number(mileage) <= filterMileageMax);
    const matchesPrice = filterPrice === '' || Number(carminimumprice) <= filterPrice;
    const matchesYear =
      (filterYearMin === '' || regYear >= filterYearMin) &&
      (filterYearMax === '' || regYear <= filterYearMax);
    const matchesBody = selectedBodyTypes.length === 0 || selectedBodyTypes.includes(bodyType);
    const matchesGear = selectedGearTypes.length === 0 || selectedGearTypes.includes(gearType);
    const matchesFuel = selectedFuelTypes.length === 0 || selectedFuelTypes.includes(fuelType);

    return (
      matchesName &&
      matchesMileage &&
      matchesYear &&
      matchesPrice &&
      matchesBody &&
      matchesGear &&
      matchesFuel
    );
  });

  const handleFavourite = (event, rowId) => {
    event.stopPropagation();
    const bodypayload = {
      "usertoken": token,
      "carid": rowId
    }
    fetch(`${config.API_URL}/markfavouritescar`, {
      "body": JSON.stringify(bodypayload),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      method: 'POST'
    }).then(() => {
      window.location.reload();
    })
  };

  const handleUnfavourite = (event, rowId) => {
    event.stopPropagation();
    const bodypayload = {
      "usertoken": token,
      "carid": rowId
    }
    fetch(`${config.API_URL}/markunfavouritescar`, {
      "body": JSON.stringify(bodypayload),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      method: 'POST'
    }).then(() => {
      window.location.reload();
    })
  }

  const sortedRows = [...filteredRows].sort((a, b) => {
    const isAFavorite = favouriteCarIds.includes(a.id);
    const isBFavorite = favouriteCarIds.includes(b.id);

    // Sort by favorite status: favorites on top, others below
    if (isAFavorite && !isBFavorite) return -1;
    if (!isAFavorite && isBFavorite) return 1;
    return 0; // If both are favorites or both are not favorites, keep original order
  });

  async function showFavourites() {
    let fetchfavourites = await fetch(`${config.API_URL}/fetchfavouritecars`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      method: 'GET'
    });

    let data = await fetchfavourites.json();
    if (data.msg === "Token has expired") {
      localStorage.removeItem('token');
      setIsLoggedIn(false);
      return { error: "Token expired" };
    }

    const carIds = data.favourites_cars.map(fav => fav.car_id);
    setFavouriteCarIds(carIds);
  }

  const applyFilters = () => {
    setFilterMileageMin(mileageRange[0]);
    setFilterMileageMax(mileageRange[1]);
    setFilterYearMin(yearRange[0]);
    setFilterYearMax(yearRange[1]);
    setFilterPrice(priceRange[1]);
  };

  const formatNumber = (value) => {
    const number = Number(value); // Convert to number
    return isNaN(number) ? value : new Intl.NumberFormat('de-CH').format(number);
};

  const resetFilters = () => {
    setFilterText('');
    setFilterMileageMin('');
    setFilterMileageMax('');
    setFilterYearMin('');
    setFilterYearMax('');
    setFilterPrice('');
    setYearRange([1900, 2025]);
    setMileageRange([0, 300000]);
    setPriceRange([0, 100000]);
    setSelectedBrand(null);
    setSelectedBodyTypes([]);
    setSelectedGearTypes([]);
    setSelectedFuelTypes([]);
  };

  const renderTable = () => (
    <>
      <Spinner loading={loading} />
      <TableContainer component={Paper} className="table-container">
        <Table className="mui-table" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className='tableth' style={{ backgroundColor: 'yellow', color: 'black' }}>
              <TableCell align="right"></TableCell>
              <TableCell>{t('imageInTable')}</TableCell>
              <TableCell>{t('vehicleInTable')}</TableCell>
              <TableCell align="right">{t('firstVehicleRegistration')}</TableCell>
              <TableCell align="right">{t('kmInTable')}</TableCell>
              {/* <TableCell align="right">{t('minimumPriceInTable')}</TableCell> */}
              {isLoggedIn ? (
                <>
                  <TableCell align="right">{t('current_bid_price')}</TableCell>
                  <TableCell align="right">{t('current_bid_winner')}</TableCell>
                </>
              ) : <TableCell />
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">{t('noCarsAvailable')}</TableCell>
              </TableRow>
            ) : (
              sortedRows.map((row) => {
                const hasValidData = row.specifications && row.specifications.car_name;
                const carName = row.specifications?.car_name || 'Unknown Car Name';
                const firstImage = row.first_image ? `${config.API_URL}${row.first_image}` : '';
                const regDate = row.specifications?.['1st reg'] || 'N/A';
                // const mileage = row.specifications?.['Mileage'] || 'N/A';
                const mileage = row.specifications?.['Mileage'] 
                  ? formatNumber(row.specifications['Mileage']) 
                  : 'N/A';
                const minPrice = isLoggedIn ? (row.minimumprice !== null ? row.minimumprice : 'N/A') : t('loginToSeePrice');
                const isFavorite = favouriteCarIds.includes(row.id);
                // const currentBidPrice = isLoggedIn ? (row.currentbidprice == 0 ? row.minimumprice : row.currentbidprice) : t('loginToSeePrice');
                const currentBidPrice = isLoggedIn 
                  ? formatNumber(row.currentbidprice == 0 ? row.minimumprice : row.currentbidprice)
                  : t('loginToSeePrice');
                const currentBidWinner = isLoggedIn ? (row.bidwinner !== null ? row.bidwinner : 'N/A') : t('loginToSeePrice');
                const carTitle = `${row.specifications?.car_name}  ${row.specifications.carmodel}, ${row.specifications.car_type}PS`;
                const carDescription = `${row.specifications?.Gear}, ${row.specifications.Body}`;

                return (
                  <TableRow
                    key={row.id}
                    onClick={hasValidData ? () => handleRowClick(row.id) : undefined}
                    style={{ cursor: hasValidData ? 'pointer' : 'default' }}
                  >
                    {isLoggedIn ? (
                      isFavorite ? (
                        <TableCell align="left" sx={{ width: '50px', paddingLeft: '10px' }}>
                          <Button onClick={(event) => handleUnfavourite(event, row.id)}>
                            <img src='/img/favouritestar.png' alt="Star" style={{ maxWidth: '20px' }} />
                          </Button>
                        </TableCell>
                      ) : (
                        <TableCell align="left" sx={{ width: '50px', paddingLeft: '10px' }}>
                          <Button onClick={(event) => handleFavourite(event, row.id)}>
                            <img src='/img/unfavouritestar.png' alt="Star" style={{ maxWidth: '20px' }} />
                          </Button>
                        </TableCell>
                      )
                    ) : (
                      <TableCell>
                      </TableCell>
                    )}

                    <TableCell className='imagetablecell'>
                      <div className="image-container">
                        {firstImage ? (
                          <>
                            <img src={firstImage} alt={carName} className="thumbnail" />
                            <div className="overlay">
                              <img src={firstImage} alt={carName} className="enlarged" />
                            </div>
                          </>
                        ) : (
                          <span>{t('noImageAvailable')}</span>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>{carTitle}</span>
                        <br />
                        <span style={{ fontSize: 'small', color: 'gray' }}>{carDescription}</span>
                      </div>
                    </TableCell>
                    <TableCell align="right">{regDate}</TableCell>
                    <TableCell align="right">{mileage}</TableCell>
                    {/* <TableCell align="right">{minPrice}</TableCell> */}
                    {isLoggedIn ? (
                      <>
                        <TableCell align="right">{currentBidPrice}.-</TableCell>
                        <TableCell
                          align="right"
                          style={{
                            backgroundColor: currentBidWinner.toLowerCase() === user.toLowerCase() ? '#20b605' : 'transparent',
                            color: currentBidWinner.toLowerCase() === user.toLowerCase() ? 'white' : 'inherit',
                            fontWeight: 'bold'
                          }}
                        >
                          {currentBidWinner}
                        </TableCell>
                      </>
                    ) : <TableCell />}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );


  const renderCard = (row) => {
    const isFavorite = favouriteCarIds.includes(row.id);
    // const currentBidPrice = isLoggedIn ? (row.currentbidprice == 0 ? row.minimumprice : row.currentbidprice) : t('loginToSeePrice');
    const currentBidPrice = isLoggedIn 
    ? (row.currentbidprice == 0 ? formatNumber(row.minimumprice) : formatNumber(row.currentbidprice)) 
    : t('loginToSeePrice');
    const carTitle = `${row.specifications?.car_name}  ${row.specifications.carmodel}, ${row.specifications.car_type}PS`
    const carDescription = `${row.specifications?.Gear}, ${row.specifications.Body}`;
    const currentBidWinner = isLoggedIn ? (row.bidwinner !== null ? row.bidwinner : 'N/A') : t('loginToSeePrice');
    const mileage = row.specifications?.['Mileage'] 
    ? formatNumber(row.specifications['Mileage']) 
    : 'N/A';
    return (
      <div
        key={row.id}
        className="card"
        onClick={() => handleRowClick(row.id)}
      >
        <div className="card-header">
          <img
            src={row.first_image ? `${config.API_URL}${row.first_image}` : ''}
            alt={row.specifications?.car_name || 'Unknown Car'}
          />
        </div>
        <div className="card-body">
          <h3 style={{ fontWeight: "bold" }}>{carTitle || 'Unknown Car Name'}</h3>
          <p>{carDescription || 'Car Description'}</p>
          <p><strong>{t('firstVehicleRegistration')}:</strong> {row.specifications?.['1st reg'] || 'N/A'}</p>
          <p><strong>{t('kmInTable')}:</strong> {mileage}</p>
          {/* <p><strong>{t('minimumPriceInTable')}:</strong> {isLoggedIn ? (row.minimumprice !== null ? row.minimumprice : 'N/A') : t('loginToSeePrice')}</p> */}
          {isLoggedIn ? (
            isFavorite ? (
              <>
                <Button onClick={(event) => handleUnfavourite(event, row.id)}><img src='/img/favouritestar.png' alt="Star" style={{ maxWidth: '20px' }} /></Button>
                <p><strong>Current bid price :</strong>{currentBidPrice}.-</p>
                <p style={{ backgroundColor: currentBidWinner.toLowerCase() === user.toLowerCase() ? '#20b605' : 'transparent', fontWeight: 'bold' ,color: currentBidWinner.toLowerCase() === user.toLowerCase() ? 'white' : 'inherit', }}><strong>Current bid winner :</strong>{currentBidWinner}</p>
              </>
            ) : (
              <>
                <Button onClick={(event) => handleFavourite(event, row.id)}><img src='/img/unfavouritestar.png' alt="Star" style={{ maxWidth: '20px' }} /></Button>
              </>
            )
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <>
      <CountDown targetDate={startDate} endTargetDate={endDate} />
      {isLoggedIn && (
        <div className='filters'>
          {isMobile ? (
            <>
              <Button
                variant="contained"
                onClick={() => setShowMobileFilters(!showMobileFilters)}
                sx={{
                  mb: 2,
                  backgroundColor: 'yellow',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#ffeb3b',
                  },
                }}
              >
                {showMobileFilters ? t('hideFilters') : t('showFilters')}
              </Button>
            </>
          ) : (
            <h3>Filter</h3>
          )}

          {(showMobileFilters || !isMobile) && (
            <Grid container spacing={2}>
              {/* Brand Filter */}
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                  <Button
                    variant="outlined"
                    onClick={() => setShowBrandFilter(!showBrandFilter)}
                    sx={{
                      mb: 2,
                      width: '100%',
                      color: 'black',
                      borderColor: 'black',
                      '&:hover': {
                        borderColor: 'black',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                  >
                    {t('carNameFilter')} {showBrandFilter ? '▲' : '▼'}
                  </Button>
                  {showBrandFilter && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: '100%',
                        p: 2,
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        boxShadow: 1,
                        background: '#fff',
                        zIndex: 1,
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6">{t('carNameFilter')}</Typography>
                      <Autocomplete
                        options={carBrands}
                        value={selectedBrand}
                        onChange={(event, newValue) => setSelectedBrand(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search car brand"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          mt: 2,
                          backgroundColor: 'yellow',
                          color: 'black',
                          '&:hover': {
                            backgroundColor: '#ffeb3b',
                          },
                        }}
                        onClick={() => {
                          setFilterText(selectedBrand || '');
                          setShowBrandFilter(false);
                        }}
                      >
                        {t('apply')}
                      </Button>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2, backgroundColor: '#ffebee', color: '#c62828' }}
                        onClick={() => {
                          setSelectedBrand(null);
                          setFilterText('');
                          setShowBrandFilter(false);
                        }}
                      >
                        {t('resetFilter')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Mileage Filter */}
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                  <Button
                    variant="outlined"
                    onClick={() => setShowMileageFilter(!showMileageFilter)}
                    sx={{
                      mb: 2,
                      width: '100%',
                      color: 'black',
                      borderColor: 'black',
                      '&:hover': {
                        borderColor: 'black',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                  >
                    {t('mileageFilter')} {showMileageFilter ? '▲' : '▼'}
                  </Button>
                  {showMileageFilter && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: '100%',
                        p: 2,
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        boxShadow: 1,
                        background: '#fff',
                        zIndex: 1,
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6">Mileage</Typography>
                      <Slider
                        value={mileageRange}
                        onChange={(event, newValue) => setMileageRange(newValue)}
                        valueLabelDisplay="auto"
                        min={0}
                        max={300000}
                        sx={{ mt: 2 }}
                      />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <TextField
                          label="Min"
                          variant="outlined"
                          size="small"
                          type="number"
                          value={mileageRange[0]}
                          onChange={(e) => setMileageRange([e.target.value, mileageRange[1]])}
                        />
                        <TextField
                          label="Max"
                          variant="outlined"
                          size="small"
                          type="number"
                          value={mileageRange[1]}
                          onChange={(e) => setMileageRange([mileageRange[0], e.target.value])}
                        />
                      </Box>
                      <Button variant="contained"
                        fullWidth
                        sx={{
                          mt: 2,
                          backgroundColor: 'yellow',
                          color: 'black',
                          '&:hover': {
                            backgroundColor: '#ffeb3b',
                          },
                        }} onClick={applyFilters}>
                        {t('apply')}
                      </Button>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2, backgroundColor: '#ffebee', color: '#c62828' }}
                        onClick={resetFilters}
                      >
                        {t('resetFilter')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Year Filter */}
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                  <Button
                    variant="outlined"
                    onClick={() => setShowYearFilter(!showYearFilter)}
                    sx={{
                      mb: 2,
                      width: '100%',
                      color: 'black',
                      borderColor: 'black',
                      '&:hover': {
                        borderColor: 'black',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                  >
                    {t('yearFilter')} {showYearFilter ? '▲' : '▼'}
                  </Button>
                  {showYearFilter && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: '100%',
                        p: 2,
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        boxShadow: 1,
                        background: '#fff',
                        zIndex: 1,
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6">Year</Typography>
                      <Slider
                        value={yearRange}
                        onChange={(event, newValue) => setYearRange(newValue)}
                        valueLabelDisplay="auto"
                        min={1900}
                        max={2025}
                        sx={{ mt: 2 }}
                      />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <TextField
                          label="Min"
                          variant="outlined"
                          size="small"
                          type="number"
                          value={yearRange[0]}
                          onChange={(e) => setYearRange([e.target.value, yearRange[1]])}
                        />
                        <TextField
                          label="Max"
                          variant="outlined"
                          size="small"
                          type="number"
                          value={yearRange[1]}
                          onChange={(e) => setYearRange([yearRange[0], e.target.value])}
                        />
                      </Box>
                      <Button variant="contained"
                        fullWidth
                        sx={{
                          mt: 2,
                          backgroundColor: 'yellow',
                          color: 'black',
                          '&:hover': {
                            backgroundColor: '#ffeb3b',
                          },
                        }} onClick={applyFilters}>
                        {t('apply')}
                      </Button>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2, backgroundColor: '#ffebee', color: '#c62828' }}
                        onClick={resetFilters}
                      >
                        {t('resetFilter')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Price Filter */}
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                  <Button
                    variant="outlined"
                    onClick={() => setShowPriceFilter(!showPriceFilter)}
                    sx={{
                      mb: 2,
                      width: '100%',
                      color: 'black',
                      borderColor: 'black',
                      '&:hover': {
                        borderColor: 'black',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                  >
                    {t('priceFilter')} {showPriceFilter ? '▲' : '▼'}
                  </Button>
                  {showPriceFilter && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: '100%',
                        p: 2,
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        boxShadow: 1,
                        background: '#fff',
                        zIndex: 1,
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6">Price</Typography>
                      <Slider
                        value={priceRange}
                        onChange={(event, newValue) => setPriceRange(newValue)}
                        valueLabelDisplay="auto"
                        min={0}
                        max={100000}
                        sx={{ mt: 2 }}
                      />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <TextField
                          label="Min"
                          variant="outlined"
                          size="small"
                          type="number"
                          value={priceRange[0]}
                          onChange={(e) => setPriceRange([e.target.value, priceRange[1]])}
                        />
                        <TextField
                          label="Max"
                          variant="outlined"
                          size="small"
                          type="number"
                          value={priceRange[1]}
                          onChange={(e) => setPriceRange([priceRange[0], e.target.value])}
                        />
                      </Box>
                      <Button variant="contained"
                        fullWidth
                        sx={{
                          mt: 2,
                          backgroundColor: 'yellow',
                          color: 'black',
                          '&:hover': {
                            backgroundColor: '#ffeb3b',
                          },
                        }} onClick={applyFilters}>
                        {t('apply')}
                      </Button>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2, backgroundColor: '#ffebee', color: '#c62828' }}
                        onClick={resetFilters}
                      >
                        {t('resetFilter')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Body Filter */}
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                  <Button
                    variant="outlined"
                    onClick={() => setShowBodyFilter(!showBodyFilter)}
                    sx={{
                      mb: 2,
                      width: '100%',
                      color: 'black',
                      borderColor: 'black',
                      '&:hover': {
                        borderColor: 'black',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                  >
                    {t('bodyFilter')} {showBodyFilter ? '▲' : '▼'}
                  </Button>
                  {showBodyFilter && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: '100%',
                        p: 2,
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        boxShadow: 1,
                        background: '#fff',
                        zIndex: 1,
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6">Body</Typography>
                      {bodyTypes.map((bodyType) => (
                        <FormControlLabel
                          key={bodyType}
                          control={
                            <Checkbox
                              checked={selectedBodyTypes.includes(bodyType)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedBodyTypes([...selectedBodyTypes, bodyType]);
                                } else {
                                  setSelectedBodyTypes(selectedBodyTypes.filter((type) => type !== bodyType));
                                }
                              }}
                            />
                          }
                          label={bodyType}
                        />
                      ))}
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          mt: 2,
                          backgroundColor: 'yellow',
                          color: 'black',
                          '&:hover': {
                            backgroundColor: '#ffeb3b',
                          },
                        }}
                        onClick={() => setShowBodyFilter(false)}
                      >
                        {t('apply')}
                      </Button>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2, backgroundColor: '#ffebee', color: '#c62828' }}
                        onClick={() => {
                          setSelectedBodyTypes([]);
                          setShowBodyFilter(false);
                        }}
                      >
                        {t('resetFilter')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Gear Filter */}
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                  <Button
                    variant="outlined"
                    onClick={() => setShowGearFilter(!showGearFilter)}
                    sx={{
                      mb: 2,
                      width: '100%',
                      color: 'black',
                      borderColor: 'black',
                      '&:hover': {
                        borderColor: 'black',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                  >
                    {t('gearFilter')} {showGearFilter ? '▲' : '▼'}
                  </Button>
                  {showGearFilter && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: '100%',
                        p: 2,
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        boxShadow: 1,
                        background: '#fff',
                        zIndex: 1,
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6">Gear</Typography>
                      {gearTypes.map((gearType) => (
                        <FormControlLabel
                          key={gearType}
                          control={
                            <Checkbox
                              checked={selectedGearTypes.includes(gearType)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedGearTypes([...selectedGearTypes, gearType]);
                                } else {
                                  setSelectedGearTypes(selectedGearTypes.filter((type) => type !== gearType));
                                }
                              }}
                            />
                          }
                          label={gearType}
                        />
                      ))}
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          mt: 2,
                          backgroundColor: 'yellow',
                          color: 'black',
                          '&:hover': {
                            backgroundColor: '#ffeb3b',
                          },
                        }}
                        onClick={() => setShowGearFilter(false)}
                      >
                        {t('apply')}
                      </Button>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2, backgroundColor: '#ffebee', color: '#c62828' }}
                        onClick={() => {
                          setSelectedGearTypes([]);
                          setShowGearFilter(false);
                        }}
                      >
                        {t('resetFilter')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Fuel Filter */}
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                  <Button
                    variant="outlined"
                    onClick={() => setShowFuelFilter(!showFuelFilter)}
                    sx={{
                      mb: 2,
                      width: '100%',
                      color: 'black',
                      borderColor: 'black',
                      '&:hover': {
                        borderColor: 'black',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                  >
                    {t('fuelFilter')} {showFuelFilter ? '▲' : '▼'}
                  </Button>
                  {showFuelFilter && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: '100%',
                        p: 2,
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        boxShadow: 1,
                        background: '#fff',
                        zIndex: 1,
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6">Fuel</Typography>
                      {fuelTypes.map((fuelType) => (
                        <FormControlLabel
                          key={fuelType}
                          control={
                            <Checkbox
                              checked={selectedFuelTypes.includes(fuelType)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedFuelTypes([...selectedFuelTypes, fuelType]);
                                } else {
                                  setSelectedFuelTypes(selectedFuelTypes.filter((type) => type !== fuelType));
                                }
                              }}
                            />
                          }
                          label={fuelType}
                        />
                      ))}
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          mt: 2,
                          backgroundColor: 'yellow',
                          color: 'black',
                          '&:hover': {
                            backgroundColor: '#ffeb3b',
                          },
                        }}
                        onClick={() => setShowFuelFilter(false)}
                      >
                        {t('apply')}
                      </Button>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2, backgroundColor: '#ffebee', color: '#c62828' }}
                        onClick={() => {
                          setSelectedFuelTypes([]);
                          setShowFuelFilter(false);
                        }}
                      >
                        {t('resetFilter')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </div>
      )}
      {isMobile ? (
        <div className="cards-container">
          {sortedRows.map((row) => renderCard(row))}
        </div>
      ) : (
        renderTable()
      )}
    </>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';


const Sidebar = ({ onSelectPage }) => {
  const {t} = useTranslation();

  return (
    <div style={styles.sidebar}>
      <ul style={{padding: 0, listStyle: 'none'}}>
        <li style={{marginBottom: 10, background: 'rgb(239 239 227)', color: 'black', padding: 10, borderRadius: 10}} onClick={() => onSelectPage('instructions')}>{t('howitworksmenugeneralinstructions')}</li>
        <li style={{marginBottom: 10, background: 'rgb(239 239 227)', color: 'black', padding: 10, borderRadius: 10}} onClick={() => onSelectPage('prices')}>{t('howitworksmenupricespervehicle')}</li>
        <li style={{marginBottom: 10, background: 'rgb(239 239 227)', color: 'black', padding: 10, borderRadius: 10}} onClick={() => onSelectPage('gc')}>{t('howitworksmenugentermscondition')}</li>
      </ul>
    </div>
  );
};

const styles = {
  sidebar: {
    width: '220px',
  }
};


export default Sidebar;
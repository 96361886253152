import React, { useState } from 'react';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  CssBaseline,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Header from './header/Header';
import config from '../config';
import './Addevent.css';

dayjs.extend(isSameOrBefore);

const AddEvent = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleAddEvent = async (e) => {
    e.preventDefault();

    if (!startDate || !endDate) {
      alert('Both start and end dates are required');
      return;
    }

    // Convert startDate and endDate to dayjs objects and add 2 hours manually
    const start = dayjs(startDate).add(2, 'hour');  // Add 2 hours to the start date
    const end = dayjs(endDate).add(2, 'hour');      // Add 2 hours to the end date

    // Validation for start and end dates
    if (start.isSameOrBefore(dayjs(), 'day')) {
      alert('Start date must be in the future');
      return;
    }

    if (end.isSameOrBefore(start, 'day')) {
      alert('End date must be after the start date');
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/addevent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Include JWT token if required
        },
        body: JSON.stringify({
          startdate: start.toISOString(),  // Send adjusted start date
          enddate: end.toISOString(),      // Send adjusted end date
        }),
      });

      if (response.ok) {
        alert('Event added successfully');
      } else {
        alert('Failed to add event');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Header />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <Container maxWidth="sm" sx={{ mt: 4 }}>
          <Typography style={{fontFamily: 'Poppins'}} variant="h4" gutterBottom>
            Add New Event
          </Typography>
          <Box
            component="form"
            onSubmit={handleAddEvent}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => (
                <TextField {...params} fullWidth margin="normal" />
              )}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => (
                <TextField {...params} fullWidth margin="normal" />
              )}
            />
            <Button style={{background: '#1D1E42', fontFamily: 'Poppins', fontWeight: 'bold', color: 'white', borderRadius: 30, paddingLeft: 20, paddingRight: 20}} type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Box>
        </Container>
      </LocalizationProvider>
    </>
  );
};

export default AddEvent;

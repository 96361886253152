import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, MenuItem, Select, InputLabel, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import carBrands from './carBrands.json';
import { fetchCarById } from '../services/fetchData';
import Header from '../components/header/Header';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from '../config';
import CarImageGallery from '../components/car/carimagegallery';
import './EditCar.css'; // Import the combined CSS file

export default function EditCar() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [carName, setCarName] = useState('');
  const [carAccident, setCarAccident] = useState('');
  const [cartype, setcartype] = useState('');
  const [minimumPrice, setMinimumPrice] = useState('');
  const [body, setBody] = useState('');
  const [fuel, setFuel] = useState('');
  const [gear, setGear] = useState('');
  const [firstReg, setFirstReg] = useState('');
  const [mileage, setMileage] = useState('');
  const [interior, setInterior] = useState('');
  const [wheelDrive, setWheelDrive] = useState('');
  const [displacement, setDisplacement] = useState('');
  const [exteriorColour, setExteriorColour] = useState('');
  const [numberOfSeats, setNumberOfSeats] = useState('');
  const [transport, setTransport] = useState('');
  const [specialEquipment, setSpecialEquipment] = useState('');
  const [serialEquipment, setSerialEquipment] = useState('');
  const [vehicleInspection, setVehicleInspection] = useState('');
  const [numberOfKeys, setNumberOfKeys] = useState('');
  const [vehicleRegistrationDocument, setvehicleRegistrationDocument] = useState('');
  const [serviceRecordBooklet, setserviceRecordBooklet] = useState('');
  const [mechanics, setmechanics] = useState('');
  const [carfinish, setcarfinish] = useState('');
  const [carmodel, setCarmodel] = useState('');
  const [vehicleidentificationnumber, setvehicleidentificationnumber] = useState('');
  const [carmasternumber, setcarmasternumber] = useState('');
  const [serviceelectrical, setserviceelectrical] = useState('');
  const [generalcondition, setgeneralcondition] = useState('');
  const [repairamount, setrepairamount] = useState('');
  const [expertReport, setexpertReport] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const [carData, setCarData] = useState({
    cartype: '',
    carAccident: '',
    minimumPrice: '',
    specifications: {},
    car_images: [],
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const getCarDetails = async () => {
      try {
        const data = await fetchCarById(id);
        setCarData(data)
        setCarName(data.specifications.car_name);
        setCarAccident(data.specifications.car_accident);
        setcartype(data.specifications.car_type);
        setMinimumPrice(data.minimumprice);
        setBody(data.specifications.Body);
        setFuel(data.specifications.Fuel);
        setGear(data.specifications.Gear);
        const firstRegFromApi = data.specifications['1st reg'];
        if (firstRegFromApi) {
          const [month, year] = firstRegFromApi.split('/');
          setFirstReg(`${year}-${month}`);
        }
        setMileage(data.specifications.Mileage);
        setInterior(data.specifications.Interior);
        setExteriorColour(data.specifications.Exterior_colour);
        setNumberOfSeats(data.specifications['Number of seats']);
        setWheelDrive(data.specifications['Wheel drive']);
        setDisplacement(data.specifications.Displacement);
        setcarfinish(data.specifications.carfinish);
        setmechanics(data.specifications.mechanics);
        setNumberOfKeys(data.specifications['Number of seats']);
        setserviceRecordBooklet(data.specifications.serviceRecordBooklet);
        setVehicleInspection(data.specifications.vehicleInspection);
        setvehicleRegistrationDocument(data.specifications.vehicleRegistrationDocument);
        setSpecialEquipment(data.special_equipment);
        setSerialEquipment(data.serial_equipment);
        setTransport(data.specifications['To be transported by the']);
        setvehicleidentificationnumber(data.specifications.vehicleRegistrationDocument);
        setcarmasternumber(data.specifications.carmasternumber);
        setserviceelectrical(data.specifications.serviceelectrical);
        setgeneralcondition(data.specifications.generalcondition);
        setrepairamount(data.specifications.repairamount);
        setCarmodel(data.specifications.carmodel);
      } catch (error) {
        navigate('/');
      }
    };

    getCarDetails();
  }, [id]);

  const handleImageChange = (e) => {
    setSelectedImages([...e.target.files]);
  };

  const handleDeleteImage = async (imagePath) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${config.API_URL}/deletecarimage/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: { image_path: imagePath },
      });
      setMessage(response.data.message);
      setCarData((prevState) => ({
        ...prevState,
        car_images: prevState.car_images.filter((image) => image !== imagePath),
      }));
    } catch (err) {
      setError(err.response.data.error || 'An error occurred while deleting the image');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const [year, month] = firstReg.split('-');
    const formattedDate = `${month}/${year}`;

    try {
      const carSpecifications = {
        car_name: carName,
        carmodel: carmodel,
        car_accident: carAccident,
        car_type: cartype,
        Body: body,
        Fuel: fuel,
        Gear: gear,
        "1st reg": formattedDate,
        Mileage: mileage,
        Interior: interior,
        "Wheel drive": wheelDrive,
        Displacement: displacement,
        "Exterior_colour": exteriorColour,
        "Number of seats": numberOfSeats,
        "To be transported by the": transport,
        "vehicleInspection": vehicleInspection,
        "numberOfKeys": numberOfKeys,
        "vehicleRegistrationDocument": vehicleRegistrationDocument,
        "serviceRecordBooklet": serviceRecordBooklet,
        "mechanics": mechanics,
        "carfinish": carfinish,
        "vehicleidentificationnumber": vehicleidentificationnumber,
        "carmasternumber": carmasternumber,
        "serviceelectrical": serviceelectrical,
        "generalcondition": generalcondition,
        "repairamount": repairamount
      };

      const formData = new FormData();
      formData.append('car_specifications', JSON.stringify(carSpecifications));
      formData.append('special_equipment', specialEquipment);
      formData.append('serial_equipment', serialEquipment);
      formData.append('minimum_price', minimumPrice);
      for (const image of selectedImages) {
        formData.append('images', image);
      }
      try {
        const token = localStorage.getItem('token');
        const response = await axios.put(`${config.API_URL}/updatecarinfo/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        setMessage(response.data.message);
        navigate(`/mycars/`);
      } catch (err) {
        setError(err.response.data.error || 'An error occurred while updating the car');
      }
    } catch (error) {
      console.error('Failed to register car:', error);
    }
  };

  const renderSpecificationsTable = (fields, title) => (
    <TableContainer component={Paper} style={{ marginBottom: '20px', borderRadius: '30px 30px 0 0' }}>
      <h4 style={{ padding: '10px 20px', backgroundColor: '#f3f3f3', margin: 0, borderTopLeftRadius: '30px', borderTopRightRadius: '30px' }}>
        {title}
      </h4>
      <Table aria-label={`${title} table`}>
        <TableBody>
          {fields.map((field, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row" style={{ paddingLeft: '20px', lineHeight: '1', borderBottom: 'none' }}>
                {field.label}
              </TableCell>
              <TableCell align="right" style={{ borderBottom: 'none' }}>
                {field.type === 'select' ? (
                  <Select
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    label={field.label}
                    fullWidth
                    style={{ minWidth: '150px' }}
                  >
                    {field.options.map((option, idx) => (
                      <MenuItem key={idx} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField
                    type={field.type}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    fullWidth
                    style={{ minWidth: '150px' }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderWideTable = (fields, title) => (
    <TableContainer component={Paper} style={{ marginBottom: '20px', borderRadius: '30px 30px 0 0', width: '100%' }}>
      <h4 style={{ padding: '10px 20px', backgroundColor: '#f3f3f3', margin: 0, borderTopLeftRadius: '30px', borderTopRightRadius: '30px' }}>
        {title}
      </h4>
      <Table aria-label={`${title} table`}>
        <TableBody>
          {fields.reduce((rows, field, index) => {
            if (index % 3 === 0) rows.push([]);
            rows[rows.length - 1].push(field);
            return rows;
          }, []).map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((field, cellIndex) => (
                <TableCell key={cellIndex} style={{ borderBottom: 'none', width: '33%' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <span>{field.label}</span>
                    {field.type === 'select' ? (
                      <Select
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        label={field.label}
                        fullWidth
                        style={{ minWidth: '150px', marginTop: '5px' }}
                      >
                        {field.options.map((option, idx) => (
                          <MenuItem key={idx} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <TextField
                        type={field.type}
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        fullWidth
                        style={{ minWidth: '150px', marginTop: '5px' }}
                      />
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const specificationsFields = [
    { label: '1st Reg', value: firstReg, onChange: setFirstReg, type: 'month' },
    { label: 'Body', value: body, onChange: setBody, type: 'select', options: ['Limousine', 'Estate car', 'Convertible', 'Coupe', 'Hatchback', 'SUV/Offroader', 'Van', 'Roadster', 'Pickup'] },
    { label: 'Fuel', value: fuel, onChange: setFuel, type: 'select', options: ['Petrol', 'Diesel', 'Electrical', 'Gas (CNG)', 'Hybrid', 'Gas (LPG)', 'Hybrid/petrol', 'Hybrid/diesel', 'Hydrogen'] },
    { label: 'Gear', value: gear, onChange: setGear, type: 'select', options: ['Manual', 'Automatic', 'Semi automatic'] },
    { label: 'Mileage', value: mileage, onChange: setMileage, type: 'number' },
    { label: 'Performance KW', value: displacement, onChange: setDisplacement, type: 'number' },
    { label: 'To be Transported', value: transport, onChange: setTransport, type: 'select', options: ['AG', 'AI', 'AR', 'BE', 'BL', 'BS', 'FR', 'GE', 'GL', 'GR', 'JU', 'LU', 'NE', 'NW', 'OW', 'SG', 'SH', 'SO', 'SZ', 'TG', 'TI', 'UR', 'VD', 'VS', 'ZG', 'ZH'] },
    { label: 'Vehicle Registration Document', value: vehicleRegistrationDocument, onChange: setvehicleRegistrationDocument, type: 'select', options: ['Available', 'Will provide later'] },
    { label: 'Body Description', value: body, onChange: setBody, type: 'text' },
    { label: 'Car Type', value: cartype, onChange: setcartype, type: 'text' },
    { label: 'Car Register Type Approval', value: cartype, onChange: setcartype, type: 'text' },
    { label: 'Other Description', value: specialEquipment, onChange: setSpecialEquipment, type: 'text' },
    { label: 'Paint Condition', value: carfinish, onChange: setcarfinish, type: 'select', options: ['Bubble effect', 'Individual paint', 'Matt paint', 'Metallic', 'Solid paint'] },
  ];

  const vehicleDescriptionFields = [
    { label: `${t('repairsTitle')}`, value: repairamount, onChange: setrepairamount, type: 'number' },
    { label: `${t('vehicleInspection')}`, value: vehicleInspection, onChange: setVehicleInspection, type: 'text' },
    { label: `${t('serviceRecordBooklet')}`, value: serviceRecordBooklet, onChange: setserviceRecordBooklet, type: 'select', options: ['Available', 'Incomplete', 'Missing'] },
    { label: `${t('vehicleRegistrationDocument')}`, value: vehicleRegistrationDocument, onChange: setvehicleRegistrationDocument, type: 'select', options: ['Available', 'Will provide later'] },
    { label: `${t('numberOfKeys')}`, value: numberOfKeys, onChange: setNumberOfKeys, type: 'select', options: [1, 2, 3, 4, 5, 6, 7] },
    { label: 'Model', value: carmodel, onChange: setCarmodel, type: 'text' },
  ];

  const conditionFields = [
    { label: `${t('mechanics')}`, value: mechanics, onChange: setmechanics, type: 'text' },
    { label: `${t('carFinish')}`, value: carfinish, onChange: setcarfinish, type: 'select', options: ['Bubble effect', 'Individual paint', 'Matt paint', 'Metallic', 'Solid paint'] },
    { label: `${t('bodyTitle')}`, value: body, onChange: setBody, type: 'select', options: ['Limousine', 'Estate car', 'Convertible', 'Coupe', 'Hatchback', 'SUV/Offroader', 'Van', 'Roadster', 'Pickup'] },
  ];

  return (
    <>
      <Header />
      <div className="car-container_1">
        <div className="car-row">
          <div className="car-column">
            <div className="mainheaderh3">
              <h3>
                {carData.specifications.car_name}, {carData.specifications.Body}, {carData.specifications['1st reg']}
              </h3>
            </div>
            <CarImageGallery carId={id} />
            <div className="car-images">
              <label>Car Images</label>
              {carData.car_images.map((image, index) => (
                <div key={index} className="image-container">
                  <img src={image} alt={`Car ${index + 1}`} style={{ width: '100px', margin: '10px' }} />
                  <Button variant="outlined" color="error" onClick={() => handleDeleteImage(image)}>
                    Delete
                  </Button>
                </div>
              ))}
            </div>
            <input type="file" multiple onChange={handleImageChange} />
          </div>
          <div className="car-column">
            {renderSpecificationsTable(specificationsFields, 'Specifications')}
          </div>
        </div>
        <div className="car-row">
          <div className="car-column">
            {renderWideTable(vehicleDescriptionFields, t('vehicleDescriptionTitle'))}
            {renderWideTable(conditionFields, t('conditionTitle'))}
          </div>
        </div>
        <div className="car-row">
          <div className="car-column">
            <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
              Save Changes
            </Button>
          </div>
        </div>
      </div>
      {message && <p className="success-message">{message}</p>}
      {error && <p className="error-message">{error}</p>}
    </>
  );
}

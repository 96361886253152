import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import config from '../config';

// Replace this with your actual publishable key from Stripe
const stripePromise = loadStripe('pk_test_51Kpg5WFdJRiLUWhUcRuEuemQEMZgh7Zoxau8siwAMW69LvbfCvv7XohGuY23Y4msl61oaDVUhytWq8zBbAVLD9ZK00GpoWgirz');

const CheckoutButton = ({ carId }) => {  // Accept carId as a prop
  const handleCheckout = async () => {
    // Fetch the Checkout Session ID from your Flask backend
    const response = await fetch(`${config.API_URL}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ car_id: carId }),  // Send car_id in the request body
    });

    const sessionId = await response.json();

    // Initialize Stripe with the publishable key
    const stripe = await stripePromise;

    // Redirect to Checkout
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionId.sessionId,
    });

    if (error) {
      console.error('Error redirecting to checkout:', error);
    }
  };

  return (
    <button role="link" onClick={handleCheckout}>
      Proceed to payment
    </button>
  );
};

export default CheckoutButton;

import React from 'react';

const Sidebar = ({ onSelectPage }) => {
  return (
    <div style={styles.sidebar}>
      <h4>How it works</h4>
      <ul style={{padding: 0, listStyle: 'none'}}>
        <li onClick={() => onSelectPage('instructions')}>Instructions</li>
        <li onClick={() => onSelectPage('prices')}>Prices</li>
        <li onClick={() => onSelectPage('gc')}>GC</li>
        <li onClick={() => onSelectPage('dpr')}>DPR</li>
      </ul>
    </div>
  );
};

const styles = {
  sidebar: {
    width: '200px',
  }
}

export default Sidebar;
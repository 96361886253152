import './footer.css';

export default function Footer() {
    return (
        <div className='footer'>
            <div>
                {/* <img src='/img/logo.png' alt='companylogo'></img> */}
                <p>AutoAuction24 AG</p>
                <p>Sinserstrasse 67</p>
                <p>6330 Cham</p>
                <p>info@autoauktion24.ch</p>
            </div>
            <div>
                <h5>Auto Auktion</h5>
                <a href="/how-it-works"><p>About US</p></a>
                <p>Jobs</p>
                <p>Imprint</p>
            </div>
            <div>
                <h5>How it works</h5>
                <p>Instructions</p>
                <p>Prices</p>
                <p>GC</p>
                <p>DPR</p>
            </div>
        </div>
    )
}


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cartoggle .Mui-expanded {
    margin: auto !important;
    margin-bottom: 10px;
}

.cartoggle .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {
    background: rgb(239, 239, 227);
    border-radius: 15px;
    color: black;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 2vh;
    width: 90% !important;
}

.cartoggle .MuiAccordion-region .MuiAccordionDetails-root {
    background: white;
    color: #1D1E42;
}

.cartoggle .MuiGrid-root.MuiGrid-item {
    border: 1px solid;
    padding-bottom: 16px;
}

.cartoggle .css-mhc70k-MuiGrid-root {
    margin-top: unset !important;
    -webkit-flex-wrap: unset !important;
    column-gap: 1%;
}



@media (max-width: 480px) {
    .css-gj1fbr-MuiGrid-root {
        max-width: unset !important;
    }
    .css-mhc70k-MuiGrid-root {
        flex-direction: column !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/car/Cartoggleinfo.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,kCAAkC;IAClC,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,4BAA4B;IAC5B,mCAAmC;IACnC,cAAc;AAClB;;;;AAIA;IACI;QACI,2BAA2B;IAC/B;IACA;QACI,iCAAiC;IACrC;AACJ","sourcesContent":[".cartoggle .Mui-expanded {\n    margin: auto !important;\n    margin-bottom: 10px;\n}\n\n.cartoggle .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {\n    background: rgb(239, 239, 227);\n    border-radius: 15px;\n    color: black;\n    font-family: 'Poppins', sans-serif;\n    margin-bottom: 2vh;\n    width: 90% !important;\n}\n\n.cartoggle .MuiAccordion-region .MuiAccordionDetails-root {\n    background: white;\n    color: #1D1E42;\n}\n\n.cartoggle .MuiGrid-root.MuiGrid-item {\n    border: 1px solid;\n    padding-bottom: 16px;\n}\n\n.cartoggle .css-mhc70k-MuiGrid-root {\n    margin-top: unset !important;\n    -webkit-flex-wrap: unset !important;\n    column-gap: 1%;\n}\n\n\n\n@media (max-width: 480px) {\n    .css-gj1fbr-MuiGrid-root {\n        max-width: unset !important;\n    }\n    .css-mhc70k-MuiGrid-root {\n        flex-direction: column !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

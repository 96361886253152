import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './Cartoggleinfo.css';

export default function Cartoggleinfo({ accordionData }) {
  return (
    <div className='cartoggle'>
      {accordionData.map((item, index) => (
        <Accordion key={index} defaultExpanded={true}>
          <AccordionSummary className='accordingbody'
            expandIcon={index === 0 ? <ArrowDownwardIcon /> : <ArrowDropDownIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography style={{fontWeight: 'bold'}} >{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails className='accordingdetails'>
            {item.details.columns ? (
              <Grid container spacing={2}>
                {item.details.columns.map((column, colIndex) => (
                  <Grid item xs={12} key={colIndex}>
                    {column.map((detail, detailIndex) => {
                      const [title, detailText] = detail.split(' - ');
                      return (
                        <Grid container key={detailIndex} spacing={2} className="detail-row">
                          <Grid item xs={12} sm={item.details.columns.length === 1 ? 3 : 6}>
                            <Typography className="detail-title">{title}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={item.details.columns.length === 1 ? 9 : 6}>
                            <Typography
                              className={`detail-text ${item.details.columns.length === 1 ? 'second-toggle-detail' : ''}`}
                              style={{ whiteSpace: 'pre-line' }} // Add this style
                            >
                              {detailText}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography style={{ whiteSpace: 'pre-line' }}>{item.details}</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
import React from "react";
import { useTranslation } from 'react-i18next';

export default function GeneralCondition() {
    const { t } = useTranslation();
    return (
        <div style={{ fontFamily: 'Poppins' }}>
            <div>
                <h2>{t('tetetermsandcond')}</h2>
                <p><strong>{t('teteauctiongeneral')}</strong></p>
                <p>{t('tetetermsandcondGemera')}</p>

                <p><strong>{t('teteauction')}</strong></p>
                <p>{t('teteauctionDescription')}</p>

                <p><strong>{t('teteuserx')}</strong></p>
                <p>{t('teteusersDescription')}</p>
                <p>{t('tetefeesDescription')}</p>

                <p><strong>{t('tetefees')}</strong></p>
                <p>{t('tetesellersAndBiddersDescription')}</p>

                <p><strong>{t('tetesellersAndBidders')}</strong></p>
                <p>{t('tetescopeOfServicesDescription')}</p>
                
                <p><strong>{t('tetescopeOfServices')}</strong></p>
                <p>{t('tetescopeOfServicesG')}</p>

                <p><strong>{t('tetespecialRightsOfAutoAuction24')}</strong></p>
                <p>{t('tetespecialRightsOfAutoAuction24Description')}</p>

                <p><strong>{t('tetedataProtection')}</strong></p>
                <p>{t('tetedataProtectionDescription')}</p>
                <p>8.1 {t('Datenschutz8.1')}</p>
                <p>8.2 {t('Datenschutz8.2')}</p>
                <p>8.3 {t('Datenschutz8.3')}</p>
                <p>8.4 {t('Datenschutz8.4')}</p>
                <p>8.5 {t('Datenschutz8.5')}</p>
                <p>8.6 {t('Datenschutz8.6')}</p>
                <p>8.7 {t('Datenschutz8.7')}</p>
                <p>8.8 {t('Datenschutz8.8')}</p>
                <p>8.9 {t('Datenschutz8.9')}</p>

                <p><strong>{t('teteseverabilityClause')}</strong></p>
                <p>{t('teteseverabilityClauseDescription')}</p>


                <p><strong>{t('teteapplicableLawAndJurisdiction')}</strong></p>
                <p>{t('teteapplicableLawAndJurisdictionDescription')}</p>

                <p>{t('tetelastUpdated')}</p>
            </div>
        </div>
    );
}

import React, { useState } from 'react';
import config from '../config';
import Header from '../components/header/Header';
import './ForgetPassword.css';
import Spinner from '../components/spinner';

function ForgetPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await fetch(`${config.API_URL}/forgot_password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      const data = await response.json();
      setLoading(false);
      setMessage(data.message);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  return (
    <>
    <Header />
    <Spinner loading={loading}/>
    <div class="mainheaderforget">
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email or username"
          required
        />
        <button type="submit" style={{background: '#1D1E42', fontFamily: 'Poppins', fontWeight: 'bold', color: 'white', borderRadius: 30}}>Send Reset Link</button>
      </form>
      {message && <p>{message}</p>}
    </div>
    </>
  );
}

export default ForgetPassword;

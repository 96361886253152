import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchEvents } from '../services/fetchData';
import Header from '../components/header/Header';


const Events = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/addevent');
  };

  useEffect(() => {
    const token = localStorage.getItem('token'); // Get JWT token from localStorage

    const fetchEventData = async () => {
      try {
        const data = await fetchEvents(token); // Call fetchEvents
        setEvents(data);
      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
    };

    fetchEventData();
  }, []);

  const getCompletionStatus = (complete) => {
    return complete === 1 ? 'Completed' : 'Not Completed';
  };

  return (
    <>
    <Header />
    <div style={{marginTop: '5vh'}}>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ background: '#1D1E42', color: 'white', fontFamily: 'Poppins', fontWeight: 'bold'}}>ID</TableCell>
            <TableCell style={{ background: '#1D1E42', color: 'white', fontFamily: 'Poppins', fontWeight: 'bold'}}>Start Date</TableCell>
            <TableCell style={{ background: '#1D1E42', color: 'white', fontFamily: 'Poppins', fontWeight: 'bold'}}>End Date</TableCell>
            <TableCell style={{ background: '#1D1E42', color: 'white', fontFamily: 'Poppins', fontWeight: 'bold'}}>Completion Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event) => (
            <TableRow key={event.id}>
              <TableCell>{event.id}</TableCell>
              <TableCell>{new Date(event.startdate).toLocaleDateString()}</TableCell>
              <TableCell>{new Date(event.enddate).toLocaleDateString()}</TableCell>
              <TableCell>{getCompletionStatus(event.complete)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <div style={{width: '80%', margin: 'auto', marginTop: '3vh'}}>
      <button style={{background: '#1D1E42', fontFamily: 'Poppins', fontWeight: 'bold', color: 'white', borderRadius: 30, paddingLeft: 20, paddingRight: 20}} onClick={() => handleRegisterClick()}>Add Event</button>
    </div>
    </div>
    </>
  );
};

export default Events;
